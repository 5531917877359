import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Crate() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Crate Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides specialised crate freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective solutions ensure the safe and efficient transport of wooden, metal, and custom crates with advanced tracking and professional care." />
                <meta name="keywords" content="Crate Freight Services, Quan Freight Management, QFM Logistics, Crate Transport Australia, Melbourne Crate Freight, Brisbane Crate Logistics, Freight Solutions, Reliable Logistics, Road Freight, Rail Freight, Air Freight, Crate Delivery, Custom Crate Handling, Cost-Effective Freight Services, Advanced Freight Technology" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Crate Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's crate freight services. Serving Melbourne, Brisbane, and Australia-wide, we ensure secure and reliable transport of wooden, metal, and custom crates using advanced logistics solutions." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-crate.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/crate-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/crate-freight-services" />˝
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/crate-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/crate-freight-services",
                                "name": "Crate Freight Services | Logistics Solutions by QFM",
                                "description": "QFM specialises in crate freight services from Melbourne, providing secure and efficient transport solutions for fragile, high-value, and oversized goods across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/crate-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/crate-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Crate Freight Services",
                                        "item": "https://qfmlogistics.com.au/crate-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/crate-freight-services#service",
                                "name": "Crate Freight Services",
                                "description": "Our crate freight services provide secure and efficient transport for fragile, high-value, and oversized goods across Australia. Based in Melbourne, we ensure timely deliveries to key cities and regional areas.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-crate.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-crate.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-crate.jpg",
                                "description": "Secure crate freight services connecting Melbourne with key locations across Australia.",
                                "name": "Crate Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-crate-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-crate-two.jpg",
                                "description": "Expert crate freight handling and efficient transport solutions for oversized and fragile goods.",
                                "name": "Crate Freight Services - Reliable Logistics Solutions",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/crate-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which areas do your crate freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our crate freight services span Melbourne, Sydney, Brisbane, Perth, Adelaide, and other major cities, as well as regional and remote areas across Australia. We ensure reliable delivery for both single crates and large projects."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/crate-freight-services",
                                            "name": "Crate Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you handle heavy or industrial crates?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we specialise in heavy-duty wooden and metal crates. Our secure processes and advanced equipment ensure the safe transport of high-weight and industrial-grade shipments."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/crate-freight-services",
                                            "name": "Crate Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods are suitable for crate freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Crate freight is ideal for fragile, high-value, or oversized goods such as machinery, electronics, artwork, and industrial components. We ensure every shipment is securely handled to protect your items during transit."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/crate-freight-services",
                                            "name": "Crate Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do I get a quote for crate freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "To request a quote, visit our Contact Us page and provide shipment details such as crate dimensions, weight, pickup, and delivery locations. Our team will provide a tailored quote promptly."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/crate-freight-services",
                                            "name": "Crate Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I track my crate shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, our tracking system provides real-time updates from pickup to delivery, ensuring complete transparency and peace of mind."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/crate-freight-services",
                                            "name": "Crate Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there size or weight restrictions for crates?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We handle crates of varying sizes and weights, including oversized and custom crates. Contact us with your crate details for tailored guidance and transport solutions."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/crate-freight-services",
                                            "name": "Crate Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Crate Freight Services</h1>
                        <p>
                            At QFM, we understand that crates often carry high-value or fragile items requiring exceptional care. Whether crafted from wood, metal, or other materials, our crate freight solutions are designed to safeguard your shipments through every step of the logistics process. From customised handling to secure transport, we ensure your goods arrive intact and on time.
                        </p>
                        {!isMobile && (
                            <p>
                                Our comprehensive crate freight services connect Melbourne with major cities across Australia, including Brisbane, Sydney, Perth, Adelaide, Darwin, Canberra, and Hobart. Leveraging road, rail, and air transport, we deliver reliable and flexible solutions tailored to meet demanding timelines and the specific requirements of your business.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="/images/qfm-item-types-crate.jpg"
                            alt="Secure and efficient crate freight services ensuring safe transport of high-value and fragile goods across Melbourne, Brisbane, and Australia-wide"
                            title="Crate Freight Services - Trusted logistics solutions by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Tailored crate freight solutions ensuring precision and safe transport for fragile goods</h5>
                        )}
                        <p>
                            From Melbourne, our crate freight services provide seamless connections to major cities such as Sydney, Brisbane, Perth, and Adelaide, as well as regional destinations across Australia, ensuring reliable and efficient delivery. <Link to="/types-of-freight">Discover the complete range of freight services we provide</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Crate Freight by QFM</h2>
                        <ul>
                            <li><b>Specialised Solutions:</b> Customised services designed for transporting wooden and metal crates, ensuring secure and efficient delivery.</li>
                            <li><b>Trusted Reliability:</b> Dependable logistics that prioritise timely and safe delivery of your fragile or high-value crate shipments.</li>
                            <li><b>Enhanced Protection:</b> Advanced handling techniques to safeguard your crates from damage during every stage of transit.</li>
                            <li><b>Australia-Wide Network:</b> Seamless freight services from Melbourne to key cities like Sydney, Brisbane, Perth, and Adelaide, including regional destinations.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="/images/qfm-item-types-crate-two.jpg"
                            loading="lazy"
                            alt="Efficient and secure crate freight services connecting Melbourne to major Australian cities and regions"
                            title="Crate Freight Handling - Reliable Logistics Solutions by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="crate-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which areas do your crate freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our crate freight services span Melbourne, Sydney, Brisbane, Perth, Adelaide, and other major cities. We also deliver to regional and remote areas across Australia.
                                    <br />
                                    Whether it’s a single crate or bulk shipments, we ensure reliable and timely deliveries to meet your requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you handle heavy or industrial crates?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Absolutely. We handle heavy-duty wooden and metal crates, including those used for industrial or oversized goods. Our secure processes and specialised equipment ensure safe transport for high-weight items.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What types of goods are suitable for crate freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Crate freight is ideal for fragile, high-value, or oversized goods like machinery, electronics, artwork, or industrial components. Our team ensures every shipment is handled with care to safeguard your goods during transit.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How do I request a quote for crate freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is simple. Visit our <Link to="/get-in-touch">Contact Us</Link> page and share your shipment details, including crate dimensions, weight, pickup, and delivery locations. Our team will provide a customised quote promptly.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Can I track my crate shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, our tracking system provides real-time updates from pickup to delivery. You’ll receive notifications to keep you informed at every step, ensuring peace of mind for your shipment.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>What industries benefit from crate freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "280px" : "0px" }}
                            >
                                <p>
                                    Crate freight supports a wide range of industries, including manufacturing, construction, art, antiques, and technology. From transporting heavy machinery to delicate artworks, we offer tailored solutions to fit each industry’s needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div
                            className={`faq-item ${openFAQ === 6 ? "active" : ""}`}
                            onClick={() => toggleFAQ(6)}
                        >
                            <h5>Are there size or weight restrictions for crates?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 6 ? "250px" : "0px" }}
                            >
                                <p>
                                    We handle crates of varying sizes and weights, including oversized and custom crates. While some restrictions may apply, we work to find suitable transport solutions for your shipment. Contact us with specific details for tailored guidance.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Crate;