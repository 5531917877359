import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import '../css/Home.css';
import Header from './Header';
import Footer from './Footer';

function Home() {
    const [isVisible, setIsVisible] = useState(false);
    const carouselContainerRef = useRef(null);
    const isAutoScrollingRef = useRef(false);
    const hasScrolledToBottomRef = useRef(false);

    // (1) Determine if mobile (width <= 768px), so we skip certain logic
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Arrow Click (Desktop-only, if you want to disable on mobile)
    const handleArrowClick = () => {
        if (isMobile) return; // skip arrow logic on mobile
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 70;
        window.scrollTo({ top: imageBottomPosition, behavior: 'smooth' });
    };

    const handleScrollDown = useCallback(() => {
        if (isMobile) return;
        if (isAutoScrollingRef.current) return;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 62;
        if (window.scrollY > 1 && !hasScrolledToBottomRef.current) {
            isAutoScrollingRef.current = true;
            window.scrollTo({ top: imageBottomPosition, behavior: 'smooth' });
            hasScrolledToBottomRef.current = true;

            document.body.style.overflow = 'hidden';
            setTimeout(() => {
                document.body.style.overflow = 'auto';
                isAutoScrollingRef.current = false;
            }, 1200);
        }
    }, [isMobile]);

    const handleScrollUp = useCallback(() => {
        if (isMobile) return;
        if (isAutoScrollingRef.current) return;
        const carouselContainer = carouselContainerRef.current;
        if (!carouselContainer) return;

        const imageBottomPosition = carouselContainer.offsetTop + carouselContainer.offsetHeight - 70;
        if (window.scrollY < imageBottomPosition && hasScrolledToBottomRef.current) {
            isAutoScrollingRef.current = true;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            hasScrolledToBottomRef.current = false;

            document.body.style.overflow = 'auto';
            setTimeout(() => {
                document.body.style.overflow = 'auto';
                isAutoScrollingRef.current = false;
            }, 1000);
        }
    }, [isMobile]);

    // Attach scroll event for desktop only
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const handleScroll = () => {
            handleScrollDown();
            handleScrollUp();
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile, handleScrollDown, handleScrollUp]);

    // Hide scrollbar logic
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    // Show/hide scroll-up button for desktop
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    // Intersection Observer for fade-in
    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    // Always scroll top on mount
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup listener
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="home-content">
            <Helmet>
                <title>Quan Freight Management | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management (QFM Logistics) provides professional road, rail, and air freight solutions connecting Melbourne, Sydney, Brisbane, Perth, Adelaide, and Tasmania. Delivering timely, cost-effective, and secure logistics tailored to your business needs across Australia." />
                <meta name="keywords" content="Quan Freight Management, QFM Logistics, freight services Australia, Melbourne logistics, Sydney freight, road freight solutions, rail freight logistics, air freight services, interstate deliveries, national freight management, secure freight transport, cost-effective logistics solutions." />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (QFM Logistics)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Quan Freight Management | Logistics Solutions by QFM" />
                <meta property="og:description" content="Quan Freight Management offers reliable road, rail, and air freight services connecting Melbourne to all major Australian cities. Trusted logistics solutions designed for businesses nationwide." />
                <meta property="og:url" content="https://qfmlogistics.com.au/" />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-home-main.jpg" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebSite",
                                "@id": "https://qfmlogistics.com.au#website",
                                "url": "https://qfmlogistics.com.au/",
                                "name": "Quan Freight Management | Logistics Solutions by QFM",
                                "description": "Quan Freight Management (QFM Logistics) provides efficient freight and logistics solutions across Australia, offering road, rail, and air freight services tailored to business needs.",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "potentialAction": {
                                    "@type": "SearchAction",
                                    "target": "https://qfmlogistics.com.au/search?q={search_term_string}",
                                    "query-input": "required name=search_term_string"
                                },
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "CreativeWork",
                                "@id": "https://qfmlogistics.com.au#license",
                                "name": "Terms and Conditions",
                                "url": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    }
                                ]
                            },
                            // Image Objects
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-main.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-main.jpg",
                                "description": "Quan Freight Management - Reliable logistics and freight solutions across Australia, connecting Melbourne, Sydney, Brisbane, and more.",
                                "name": "Quan Freight Management | Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-intro.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-intro.jpg",
                                "description": "Freight services from Melbourne to Australia-wide, including pallet delivery and logistics solutions.",
                                "name": "Quan Freight Management - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-interstate-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-interstate-freight.jpg",
                                "description": "Interstate freight services from Melbourne to major Australian cities, specialising in pallet delivery and tailored logistics solutions.",
                                "name": "Australia-Wide Road and Rail Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-air-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-air-freight.jpg",
                                "description": "Air freight services Australia-wide, providing fast delivery and efficient logistics solutions for pallets and time-sensitive shipments.",
                                "name": "Australia-Wide Air Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-local-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-local-freight.jpg",
                                "description": "Local freight services in Australia's main cities, offering reliable pallet delivery and tailored logistics solutions.",
                                "name": "Local Freight Services in Major Australian Cities - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-home-technology.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-home-technology.jpg",
                                "description": "Advanced freight technology solutions enabling seamless logistics across Australia, including pallet delivery and real-time tracking.",
                                "name": "Cutting-Edge Freight Technology Solutions - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-australia-wide-logistics.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-australia-wide-logistics.jpg",
                                "description": "Comprehensive freight services across Australia, including pallets, cartons, and project logistics solutions in major metro areas.",
                                "name": "Australia-Wide Freight Logistics - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className='home-content-container-parent'>
                <Header />
                <div className="maincontent-one">
                    <div className="carousel-container" ref={carouselContainerRef}>
                        <div className="carousel-arrow-down" onClick={handleArrowClick}>
                            <i className="fas fa-chevron-down"></i>
                        </div>
                        <img
                            src="/images/qfm-home-main.jpg"
                            alt="Quan Freight Management - Reliable logistics and freight solutions across Australia, connecting Melbourne, Sydney, Brisbane, and more."
                            className="carousel-image"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="home-content-container">
                    {isVisible && !isMobile && (
                        <div className="vertical-line"></div>
                    )}
                    <div className="default-intro-section">
                        {isMobile && (
                            <img
                                src="/images/qfm-home-mobile-main.png"
                                alt="Freight services from Melbourne to Australia-wide, including pallet delivery and logistics solutions"
                                title="Quan Freight Managament - Logistics Solutions by QFM"
                                loading="lazy"
                            />
                        )}

                        {isMobile && (
                            <div className="home-content-spacer-container">
                                {isMobile && (
                                    <h1><span><b>QUAN</b></span><span>FREIGHT</span><span>MANAGEMENT</span></h1>

                                )}
                            </div>
                        )}
                        {isVisible && !isMobile && (
                            <div className="default-intro-section-text-content">
                                <h1>Quan Freight Management</h1>
                                <p>Quan Freight Management (QFM) is a premier, Australian-owned and operated logistics company specializing in domestic freight across Australia, whether by road, rail, or air. We provide comprehensive, integrated door-to-door logistics solutions, including land transport, distribution, and value-added services.</p>
                                <p>With a focus on reliability and efficiency, QFM leverages advanced technology and a skilled team to ensure seamless operations. From small parcels to oversized goods, our tailored services meet the diverse needs of businesses, ensuring secure and timely deliveries nationwide.</p>
                            </div>
                        )}
                        {isVisible && !isMobile && (
                            <div className="default-intro-section-image-container">
                                <img
                                    src="/images/qfm-home-intro.jpg"
                                    alt="Freight services from Melbourne to Australia-wide, including pallet delivery and logistics solutions"
                                    title="Quan Freight Managament - Logistics Solutions by QFM"
                                    loading="lazy"
                                />
                            </div>
                        )}
                    </div>
                    {isMobile && (
                        <div className="australia-section-text-content" style={{ backgroundColor: 'rgba(0, 0, 128, 0.1)' }}>
                            <p><b>Quan Freight Management (QFM)</b> is a premier, Australian-owned and operated logistics company specializing in domestic freight across Australia, whether by road, rail, or air. We provide comprehensive, integrated door-to-door logistics solutions, including land transport, distribution, and value-added services.</p>
                        </div>
                    )}
                    <div className="home-section-2">
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/road-freight-services">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-interstate-freight.jpg"
                                        alt="Interstate freight services from Melbourne to major Australian cities, specialising in pallet delivery and tailored logistics solutions"
                                        title="Australia-Wide Road and Rail Freight Services - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Road Freight</h4>
                                        {isVisible && !isMobile && (
                                            <p>QFM provides tailored interstate freight solutions through a vast carrier network.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/air-freight-services">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-air-freight.jpg"
                                        alt="Air freight services Australia-wide, providing fast delivery and efficient logistics solutions for pallets and time-sensitive shipments"
                                        title="Australia-Wide Air Freight Services - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Air Freight</h4>
                                        {isVisible && !isMobile && (
                                            <p>We offer overnight air freight for satchels, cartons, and small pallets Australia-wide.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/local-freight-services">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-local-freight.jpg"
                                        alt="Local freight services in Australia's main cities, offering reliable pallet delivery and tailored logistics solutions"
                                        title="Local Freight Services in Major Australian Cities - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Local Freight</h4>
                                        {isVisible && !isMobile && (
                                            <p>Reliable local transport with trusted couriers for ad-hoc and permanent needs.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="home-section-2-image-card appearing-effect" style={{ cursor: 'pointer' }}>
                            <div className="home-section-2-image-container">
                                <Link to="/freight-management-technology">
                                    <div className="filter-overlay"></div>
                                    <img
                                        src="/images/qfm-home-technology.jpg"
                                        alt="Advanced freight technology solutions enabling seamless logistics across Australia, including pallet delivery and real-time tracking"
                                        title="Cutting-Edge Freight Technology Solutions - Logistics Solutions by QFM"
                                        loading="lazy"
                                    />
                                    <div className="home-section-2-imagecard-overlay-div">
                                        <h4>Technology</h4>
                                        {isVisible && !isMobile && (
                                            <p>Advanced tech ensures real-time tracking and seamless communication.</p>
                                        )}
                                    </div>
                                    <div className="home-section-2-imagecard-overlay-divtwo">
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="australia-section appearing-effect">
                        <div className="australia-section-image-container">
                            <img
                                src="/images/qfm-australia-wide-logistics.jpg"
                                alt="Comprehensive freight services across Australia, including pallets, cartons, and project logistics solutions in major metro areas"
                                title="Australia-Wide Freight Logistics - Logistics Solutions by QFM"
                                loading="lazy"
                            />
                        </div>
                        <div className="australia-section-text-content">
                            <h2>Australia-wide Freight</h2>
                            <ul>
                                <li><b>Comprehensive Freight Solutions:</b> Offering road, rail, air, and sea freight services tailored to your business needs.</li>
                                <li><b>Nationwide Coverage:</b> Seamlessly connecting Melbourne to major cities and regional areas across Australia.</li>
                                <li><b>Specialised Freight Services:</b> Expertise in handling dangerous goods, oversized items, and time-critical deliveries.</li>
                                <li><b>Reliable Local Freight:</b> Same-day courier services, taxi trucks, and permanent hire available within Melbourne.</li>
                                <li><b>Advanced Logistics Technology:</b> Real-time tracking and updates for complete shipment visibility.</li>
                                <li><b>Flexible Service Levels:</b> Catering to urgent, same-day, and scheduled delivery requirements.</li>
                                <li><b>Customised Solutions:</b> Freight options designed to accommodate pallets, cartons, crates, and industrial equipment.</li>                                
                            </ul>
                        </div>
                    </div>
                    <div className="default-quote appearing-effect">
                        <Link to="/get-in-touch">
                            <div className="default-quote-quote">
                                <h4>Get In Touch</h4>
                            </div>
                        </Link>
                        <div className="default-quote-text-content">
                            <h5>QFM seamlessly connects Melbourne to destinations across Australia</h5>
                            <p>
                                Leveraging advanced technology and a highly skilled team, we provide freight services to major cities and regional hubs.
                            </p>
                        </div>
                    </div>
                    <div className="home-section-5 appearing-effect">
                        <div className="home-section-5-text-content">
                            <div className="info-container">
                                <img
                                    src="/images/qfm-home-mission.png"
                                    alt="Australia-wide freight services, handling pallets, cartons, and project logistics with a focus on reliability and efficiency"
                                    title="Australia-Wide Freight Logistics - Logistics Solutions by QFM"
                                    loading="lazy"
                                    className="info-icon"
                                />
                                <div>
                                    <h3>Our Mission</h3>
                                    <p>Our mission is to deliver peace of mind with reliable, high-quality solutions at competitive prices. Our flexibility allows us to adapt swiftly to customer needs, ensuring efficient operations that lower costs for our clients.</p>
                                </div>
                            </div>
                            <div className="info-container">
                                <img
                                    src="/images/qfm-home-knowledge.png"
                                    alt="Expert freight services across Australia, specialising in pallets, cartons, and project logistics with trusted expertise"
                                    title="Trusted Expertise in Freight Solutions - Logistics Solutions by QFM"
                                    loading="lazy"
                                    className="info-icon"
                                />
                                <div>
                                    <h3>Expert Knowledge</h3>
                                    <p>With years of experience, our expertise has earned the trust and recognition of our customers. We are committed to delivering high-quality service, exceeding expectations and building strong, lasting relationships.</p>
                                </div>
                            </div>
                            <div className="info-container">
                                <img
                                    src="/images/qfm-home-vision.png"
                                    alt="Innovative freight solutions across Australia with a vision for excellence in logistics and customer satisfaction"
                                    title="Vision for Excellence - Logistics Solutions by QFM"
                                    loading="lazy"
                                    className="info-icon"
                                />
                                <div>
                                    <h3>Our Vision</h3>
                                    <p>Our vision is not to become the biggest, but to become the best. We focus on innovation while prioritizing our environment and community. We treat our customers, partners, and suppliers with the respect we’d expect ourselves. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && !isMobile && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}

                <Footer />
            </div>
        </div>
    );
}

export default Home;