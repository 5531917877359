import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../css/OurStory.css';

function OurStory() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // skip for mobile if not desired
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const scrollToTop = () => {
    if (isMobile) return; // skip for mobile if we don't want the scroll-up
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="ourstory-main-content">
      <Helmet>
        <title>Our Story | Logistics Solutions by QFM</title>
        <meta name="description" content="Discover Quan Freight Management's commitment to excellence in freight logistics. Learn about our innovative approach, expert team, and dedication to delivering reliable freight solutions across Australia. From Melbourne to all major cities, we provide tailored services with advanced technology and a customer-first ethos." />
        <meta name="keywords" content="Our Story, Logistics Solutions, Quan Freight Management, QFM, QFM Logistics, Freight Logistics Australia, Melbourne Freight Services, Major Cities Freight, Reliable Freight Solutions, Tailored Freight Services, Advanced Freight Technology, Customer-First Logistics, Expert Freight Team, Freight Excellence, Innovative Logistics Approach" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Our Story | Logistics Solutions by QFM" />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-our-story.jpg" />
        <meta property="og:description" content="Learn about Quan Freight Management's journey, from humble beginnings to becoming a trusted name in freight logistics. Explore our commitment to innovation, customer service, and delivering seamless freight solutions across Australia." />
        <meta property="og:url" content="https://qfmlogistics.com.au/our-story" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/our-story" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "AboutPage",
                "@id": "https://qfmlogistics.com.au/our-story#webpage",
                "url": "https://qfmlogistics.com.au/our-story",
                "name": "Our Story | Logistics Solutions by QFM",
                "description": "Discover Quan Freight Management’s journey in redefining logistics across Australia. Learn about our roots, growth, and commitment to reliable freight services for pallets, cartons, and large-scale projects.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/our-story#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "CreativeWork",
                "@id": "https://qfmlogistics.com.au#license",
                "name": "Terms and Conditions",
                "url": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/our-story#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Our Story",
                    "item": "https://qfmlogistics.com.au/our-story"
                  }
                ]
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-our-story.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-our-story.jpg",
                "description": "Quan Freight Management's journey in redefining logistics with reliable freight services across Australia, specialising in pallets, cartons, and project freight",
                "name": "Our Story - Quan Freight Management's reliable freight solutions across Australia",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
        </script>
      </Helmet>
      <div className="ourstory-content-container">
        <Header isScrolledInitially={true} />
        {!isMobile && (
          <div className="vertical-line"></div>
        )}
        <div className="ourstory-intro-section ourstory-intro-section-add appearing-effect">
          <div className="ourstory-intro-section-text-content">
            <h1>Our Story</h1>
            <p>
              Chris and John first crossed paths over a decade ago while working together at a local transport company. Their shared passion for the logistics industry and a mutual drive to innovate and excel in the field forged a strong professional connection. Over time, their collaboration grew into a vision for something greater.
            </p>
            <p>
              With extensive experience in local and domestic freight, they brought a deep understanding of the industry’s challenges and opportunities to the foundation of Quan Freight Management. Built on pillars of reliability, innovation, and customer-centric solutions, their mission is to redefine logistics and deliver unparalleled service across Australia.
            </p>
          </div>
          <div className="ourstory-intro-section-image-container">
            <img
              src="/images/qfm-our-story.jpg"
              alt="Quan Freight Management's journey in redefining logistics with reliable freight services across Australia, specialising in pallets, cartons, and project freight"
              title="Our Story - Quan Freight Management's reliable freight solutions across Australia"
              loading="lazy"
            />
          </div>
        </div>
        <div className="default-quote appearing-effect">
          <Link to="/get-in-touch"><div className="default-quote-quote">
            <h4>Get In Touch</h4>
          </div>
          </Link>
          {!isMobile && (
            <div className="default-quote-text-content">
              <h5>Real-time tracking and predictive analytics enable proactive freight management</h5>
              <p>
                Our optimised route planning and automated systems drive efficiency. <Link to="/our-services">View all services</Link>
              </p>
            </div>
          )}
        </div>
        <div className="ourstory-section-3 appearing-effect">
          <div className="ourstory-section-3-text-content">
            <h2>The Origin of "Quan" Freight Management</h2>
            <p>
              John and Chris were sitting in a café, exhausted but exhilarated after a long week of brainstorming ideas for their new freight management business. They’d jotted down countless potential names, but none seemed to truly capture the essence of what they wanted to build.
            </p>
            <p>
              As they sipped their coffee, they reflected on what was most important to them as business owners: balance, efficiency, quality, and integrity. They sought a name that would resonate not only with customers but also embody the values shaping their approach to logistics.
            </p>
            <p>
              Then, almost as if by fate, they both recalled a concept from a conversation months prior—a term from the movie <em>Jerry Maguire</em>. In the film, ‘Quan’ represents a sense of wholeness and balance, an ideal that extends beyond business to life as a whole. It felt like the perfect representation of their vision.
            </p>
            <p>
              Beyond the movie’s meaning, ‘Quan’ in some traditions signifies harmony between the spiritual and practical—the balance they aim to strike in every facet of their business. For them, it wasn’t just about getting the job done; it was about doing it with integrity, focus, and care. The name resonated deeply, capturing both their philosophy and the service they strive to provide.
            </p>
            <p>
              From that moment, the decision was clear. Quan Freight Management was born, reflecting how John and Chris believe logistics should be handled—with balance and dedication every step of the way.
            </p>
          </div>
        </div>
        {isVisible && (
          <div className="scroll-up" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}
        <Footer />
      </div>
    </div >
  );
}

export default OurStory;