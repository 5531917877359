import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import '../css/Header.css';

function Header({ isScrolledInitially = false }) {
  // Desktop scroll state
  const [isScrolled, setIsScrolled] = useState(false);

  // Detect mobile
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Mobile-specific scrolled state
  const [isScrolledMobile, setIsScrolledMobile] = useState(
    isMobile && isScrolledInitially
  );

  // Handle window resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Re-check if we switch from desktop to mobile while `isScrolledInitially` is true
  useEffect(() => {
    if (isMobile && isScrolledInitially) {
      setIsScrolledMobile(true);
    }
  }, [isMobile, isScrolledInitially]);

  // **Desktop** scroll logic
  useEffect(() => {
    if (isMobile) return; // skip if mobile

    const handleDesktopScroll = () => {
      // If we want the desktop header also scrolled initially:
      if (window.scrollY > 300 || isScrolledInitially) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleDesktopScroll(); // run on mount
    window.addEventListener('scroll', handleDesktopScroll);
    return () => window.removeEventListener('scroll', handleDesktopScroll);
  }, [isMobile, isScrolledInitially]);

  // **Mobile** scroll logic -- skip if `isScrolledInitially` is true
  useEffect(() => {
    // If we're forcing mobile scrolled from the start, do not attach scroll toggling
    if (!isMobile || isScrolledInitially) return;

    const handleMobileScroll = () => {
      setIsScrolledMobile(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleMobileScroll);
    return () => window.removeEventListener('scroll', handleMobileScroll);
  }, [isMobile, isScrolledInitially]);

  // Final class for the header
  const headerClass = isMobile
    ? isScrolledMobile
      ? 'scrolledMobile'
      : ''
    : isScrolled
    ? 'scrolled'
    : '';

  return (
    <header className={`App-header ${headerClass}`}>
      <div className="App-header-reposition">
        {/* Logo Section */}
        <div className="header-section-1">
          <Link to="/" className="logo-link">
            {isScrolled ? (
              <img
                src="/images/qfm-logo.png"
                alt="Quan Freight Management Logo"
                className="qfm-logo-scrolled"
                loading="lazy"
              />
            ) : (
              <img
                src="/images/qfm-logo-home.png"
                alt="Quan Freight Management Logo"
                className="qfm-logo-main"
                loading="lazy"
              />
            )}
          </Link>
        </div>

        {/* Title + Nav + Socials */}
        <div className="header-section-2">
          <h2>QUAN FREIGHT MANAGEMENT</h2>
          
          {/* 1) Checkbox to toggle the nav on mobile */}
          <input
            type="checkbox"
            id="mobile-menu-toggle"
            className="mobile-menu-toggle"
          />

          {/* 2) The hamburger icon */}
          <label htmlFor="mobile-menu-toggle" className="hamburger-icon">
            <span></span>
            <span></span>
            <span></span>
          </label>

          {/* 3) The actual navigation */}
          <nav>
            <ul className="header-nav-links">
              {/* The 'Home' link is conditionally shown on desktop when scrolled, 
                  but you can show it always on mobile, if you prefer. */}
              {(isScrolled || isMobile) && (
                <li key="home">
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Home
                    {/* Desktop border animations remain in place */}
                    <span className="border-top-left"></span>
                    <span className="border-top-right"></span>
                    <span className="border-right"></span>
                    <span className="border-bottom"></span>
                    <span className="border-left"></span>
                    <span className="background-fill"></span>
                  </NavLink>
                </li>
              )}

              {/* Services (with dropdown on desktop; 
                  On mobile, it just collapses into the hamburger menu) */}
              <li className="dropdown" key="services">
                <NavLink
                  to="/our-services"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Services
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu dropdown-services">
                  <li key="road">
                    <NavLink to="/road-freight-services">Road Freight</NavLink>
                  </li>
                  <li key="rail">
                    <NavLink to="/Rail-freight-services">Rail Freight</NavLink>
                  </li>
                  <li key="air">
                    <NavLink to="/air-freight-services">Air Freight</NavLink>
                  </li>
                  <li key="local">
                    <NavLink to="/local-freight-services">Local Freight</NavLink>
                  </li>
                  <li key="project">
                    <NavLink to="/project-freight-services">Project Freight</NavLink>
                  </li>
                  <li key="dg">
                    <NavLink to="/dangerous-goods-freight-services">DG Freight</NavLink>
                  </li>
                  <li key="general">
                    <NavLink to="/general-freight-services">General Freight</NavLink>
                  </li>
                  <li key="express">
                    <NavLink to="/express-freight-services">Express Freight</NavLink>
                  </li>
                </ul>
              </li>


              <li className="dropdown" key="itemtypes">
                <NavLink
                  to="/types-of-freight"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Item Types
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
                <ul className="dropdown-menu">
                  <li key="pallet">
                    <NavLink to="/pallet-freight-services">Pallet</NavLink>
                  </li>
                  <li key="carton">
                    <NavLink to="/carton-freight-services">Carton</NavLink>
                  </li>
                  <li key="crate">
                    <NavLink to="/crate-freight-services">Crate</NavLink>
                  </li>
                  <li key="satchel">
                    <NavLink to="/satchel-freight-services">Satchel</NavLink>
                  </li>
                  <li key="envelope">
                    <NavLink to="/envelope-freight-services">Envelope</NavLink>
                  </li>
                  <li key="drum">
                    <NavLink to="/drum-freight-services">Drum</NavLink>
                  </li>
                  <li key="ibc">
                    <NavLink to="/ibc-freight-services">IBC</NavLink>
                  </li>
                  <li key="panels">
                    <NavLink to="/panel-freight-services">Panel</NavLink>
                  </li>
                  <li key="stillage">
                    <NavLink to="/stillage-freight-services">Stillage</NavLink>
                  </li>
                  <li key="Semi">
                    <NavLink to="/semi-freight-services">Semi</NavLink>
                  </li>
                  <li key="B-Double">
                    <NavLink to="/bdouble-freight-services">B-Double</NavLink>
                  </li>
                  <li key="container">
                    <NavLink to="/container-freight-services">Container</NavLink>
                  </li>
                </ul>
              </li>

              <li key="technology">
                <NavLink
                  to="/freight-management-technology"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Technology
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
              </li>

              <li key="our-story">
                <NavLink
                  to="/our-story"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Our Story
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
              </li>

              <li key="tnc">
                <NavLink
                  to="/terms-and-conditions"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  T&amp;C
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
              </li>

              <li key="get-in-touch">
                <NavLink
                  to="/get-in-touch"
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  Get In Touch
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </NavLink>
              </li>

              <li key="customer-portal">
                <a
                  href="https://qfmlogistics.machship.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Customer Portal
                  <span className="border-top-left"></span>
                  <span className="border-top-right"></span>
                  <span className="border-right"></span>
                  <span className="border-bottom"></span>
                  <span className="border-left"></span>
                  <span className="background-fill"></span>
                </a>
              </li>
            </ul>
          </nav>

          {/* Social icons only appear if scrolled, on desktop. 
              On mobile, you can do something else or hide them entirely. */}
          {isScrolled && (
            <div className="header-social-icons">
              <a
                href="https://www.linkedin.com/company/qfm-logistics"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-icon"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          )}
        </div>
        
      </div>
    </header>
  );
}

export default Header;