import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.css';

import Home from './components/Home.js';
import OurServices from './components/OurServices.js';
// import ServicesMelbourne from './components/ServicesMelbourne.js';
// import ServicesSydney from './components/ServicesSydney.js';
// import ServicesBrisbane from './components/ServicesBrisbane.js';
// import ServicesPerth from './components/ServicesPerth.js';
// import ServicesAdelaide from './components/ServicesAdelaide.js';
import OurStory from './components/OurStory';
import Technology from './components/Technology.js';
import GetInTouch from './components/GetInTouch.js';
import DevelopmentMessage from './components/DevelopmentMessage';
import TermsandConditions from './components/TermsandConditions.js';
import ItemTypes from './components/ItemTypes.js';
import PalletServices from './components/ItemPallet.js';
import CartonServices from './components/ItemCarton.js';
import CrateServices from './components/ItemCrate.js';
import SatchelServices from './components/ItemSatchel.js';
import EnvelopeServices from './components/ItemEnvelope.js';
import DrumServices from './components/ItemDrum.js';
import IBCServices from './components/ItemIBC.js';
import PanelServices from './components/ItemPanel.js';
import StillageServices from './components/ItemStillage.js';
import SemiServices from './components/ItemSemi.js';
import BdoubleServices from './components/ItemBdouble.js';
import ContainerServices from './components/ItemContainer.js';
import ServicesRoad from './components/ServicesRoad.js';
import ServicesRail from './components/ServicesRail.js';
import ServicesAir from './components/ServicesAir.js';
import ServicesLocal from './components/ServicesLocal.js';
import ServicesProject from './components/ServicesProject.js';
import ServicesDG from './components/ServicesDG.js';
import ServicesGeneral from './components/ServicesGeneral.js';
import ServicesExpress from './components/ServicesExpress.js';

function App() {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  // Detect mobile or tablet using user-agent
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isMobile =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);

    // Optional: Also check screen width if you want to treat narrow desktops as mobile
    // const matchesNarrowScreen = window.matchMedia('(max-width: 768px)').matches;
    // setIsMobileOrTablet(isMobile || matchesNarrowScreen);

    // If you ONLY want user-agent detection, do this:
    setIsMobileOrTablet(isMobile);
  }, []);

  // Define your routes
  const router = createBrowserRouter(
    [
      { path: '/', element: <Home /> },
      { path: '/our-story', element: <OurStory /> },
      { path: '/our-services', element: <OurServices /> },
      { path: '/freight-management-technology', element: <Technology /> },
      { path: '/get-in-touch', element: <GetInTouch /> },
      { path: '/developmentmessage', element: <DevelopmentMessage /> },
      // { path: '/melbourne-freight-services', element: <ServicesMelbourne /> },
      // { path: '/sydney-freight-services', element: <ServicesSydney /> },
      // { path: '/brisbane-freight-services', element: <ServicesBrisbane /> },
      // { path: '/perth-freight-services', element: <ServicesPerth /> },
      // { path: '/adelaide-freight-services', element: <ServicesAdelaide /> },
      { path: '/terms-and-conditions', element: <TermsandConditions /> },
      { path: '/types-of-freight', element: <ItemTypes /> },
      { path: '/pallet-freight-services', element: <PalletServices /> },
      { path: '/carton-freight-services', element: <CartonServices /> },
      { path: '/crate-freight-services', element: <CrateServices /> },
      { path: '/satchel-freight-services', element: <SatchelServices /> },
      { path: '/envelope-freight-services', element: <EnvelopeServices /> },
      { path: '/drum-freight-services', element: <DrumServices /> },
      { path: '/ibc-freight-services', element: <IBCServices /> },
      { path: '/panel-freight-services', element: <PanelServices /> },
      { path: '/stillage-freight-services', element: <StillageServices /> },
      { path: '/semi-freight-services', element: <SemiServices /> },
      { path: '/bdouble-freight-services', element: <BdoubleServices /> },
      { path: '/container-freight-services', element: <ContainerServices /> },
      { path: '/road-freight-services', element: <ServicesRoad /> },
      { path: '/rail-freight-services', element: <ServicesRail /> },
      { path: '/air-freight-services', element: <ServicesAir /> },
      { path: '/local-freight-services', element: <ServicesLocal /> },
      { path: '/project-freight-services', element: <ServicesProject /> },
      { path: '/dangerous-goods-freight-services', element: <ServicesDG /> },
      { path: '/general-freight-services', element: <ServicesGeneral /> },
      { path: '/express-freight-services', element: <ServicesExpress /> },
    ],
    {
      future: {
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      },
    }
  );

  // 1) If mobile or tablet, show DevelopmentMessage ONLY
  // if (isMobileOrTablet) {
  //   return <DevelopmentMessage />;
  // }

  // 2) Otherwise, render the normal desktop routes
  return (
    <HelmetProvider>
      <RouterProvider router={router}>
        <div className="App">
          
          <div className="App-content">
          </div>
        </div>
      </RouterProvider>
    </HelmetProvider>
  );
}

export default App;