import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function RailFreight() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/our-services"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Rail Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management offers reliable rail freight services connecting Melbourne, Brisbane, Sydney, and Perth. Our cost-effective and sustainable logistics solutions ensure efficient transport of goods with advanced tracking and expert handling." />
                <meta name="keywords" content="Rail Freight Services, Quan Freight Management, QFM Logistics, Rail Transport Australia, Melbourne Rail Freight, Brisbane Rail Logistics, Freight Solutions, Reliable Rail Logistics, Long-Distance Freight, Interstate Freight, Sustainable Freight, Advanced Rail Freight Solutions, Cost-Effective Logistics Services" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Rail Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's rail freight services. Serving Melbourne, Brisbane, Sydney, and Perth, we ensure efficient and eco-friendly delivery with advanced logistics solutions and professional handling." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/rail-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/rail-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/rail-freight-services",
                                "name": "Rail Freight Services | Logistics Solutions by QFM",
                                "description": "QFM offers reliable rail freight services connecting Melbourne to Brisbane, Sydney, and Perth. Our cost-effective and sustainable solutions ensure secure and efficient transport of goods across Australia's extensive rail network.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/rail-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Our Services",
                                        "item": "https://qfmlogistics.com.au/our-services"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Rail Freight Services",
                                        "item": "https://qfmlogistics.com.au/rail-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#service",
                                "name": "Rail Freight Services",
                                "description": "Our rail freight services connect Melbourne to Brisbane, Sydney, and Perth, offering efficient and environmentally friendly logistics solutions for bulk and specialised shipments.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-rail-freight.jpg",
                                "description": "Reliable rail freight services connecting Melbourne to Brisbane, Sydney, and Perth.",
                                "name": "Rail Freight Services – Reliable Logistics by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-services-rail-freight-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-services-rail-freight-two.jpg",
                                "description": "Efficient rail freight services with sustainable logistics solutions across Australia's extensive rail network.",
                                "name": "Rail Freight Services – Comprehensive Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/rail-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What are the primary routes for your rail freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our rail freight services connect Melbourne to major cities like Brisbane, Sydney, and Perth, providing reliable and cost-effective logistics solutions."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported via rail freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Rail freight is suitable for transporting bulk goods, pallets, heavy machinery, and specialised cargo requiring secure handling."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How does rail freight compare to road freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Rail freight is ideal for long-distance transport, offering cost savings and reduced environmental impact compared to road freight. Road freight is better suited for shorter distances and last-mile delivery."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can I track my rail freight shipment?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, all rail freight shipments include real-time tracking to keep you updated throughout the journey."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a rail freight quote?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page and provide shipment details such as origin, destination, size, and weight for a customised quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/rail-freight-services",
                                            "name": "Rail Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]

                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Rail Freight Services</h1>

                        <p>
                            With a focus on sustainability and efficiency, QFM's Rail Freight services provide a reliable solution for moving large volumes of goods across Australia. By utilising the expansive national rail network, we deliver cost-effective transport while minimising environmental impact. This makes rail freight an ideal option for businesses seeking both economic and eco-friendly logistics solutions.
                        </p>
                        {!isMobile && (
                            <p>
                                Whether it’s bulk commodities, specialised cargo, or high-volume shipments, our tailored services ensure your goods are handled with precision and care. From Melbourne to key hubs like Sydney, Brisbane, and Perth, we prioritise timely deliveries and seamless operations, supporting your business with dependable and sustainable freight management.
                            </p>
                        )}

                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="/images/qfm-services-rail-freight.jpg"
                            alt="Efficient rail freight services connecting Melbourne to Australia's key logistics hubs"
                            title="Rail Freight Services – Sustainable Logistics Solutions by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Efficient and sustainable rail freight solutions tailored to your logistics requirements</h5>
                        )}
                        <p>
                            QFM's rail freight services leverage Australia's extensive rail network to provide reliable, cost-effective, and eco-friendly transport solutions. <Link to="/our-services">Explore the complete range of logistics services we offer</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add">
                        <h2>Melbourne Rail Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>Cost-Effective Transport:</b> Rail freight offers a more economical option for moving large quantities of goods over long distances, helping you reduce transportation costs.
                            </li>
                            <li>
                                <b>Sustainability:</b> Minimize your carbon footprint with our eco-friendly rail solutions, contributing to a greener environment.
                            </li>
                            <li>
                                <b>Intermodal Solutions:</b> Seamlessly integrate rail freight with road and other transportation methods for a comprehensive and flexible logistics strategy.
                            </li>
                            <li>
                                <b>Reliable Scheduling:</b> Benefit from consistent and predictable delivery schedules, ensuring your goods arrive when needed.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="/images/qfm-services-rail-freight-two.jpg"
                            loading="lazy"
                            alt="Comprehensive rail freight solutions connecting Melbourne to major cities and regional centres across Australia"
                            title="Rail Freight Services – Dependable and Sustainable Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/our-services"><h4>View all Services</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="rail-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What are the main routes for your rail freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our rail freight services primarily connect Melbourne to key destinations such as Brisbane, Sydney, and Perth. These routes are carefully managed to ensure timely and cost-efficient transport for all shipments.
                                    <br />
                                    If you have other destinations in mind, contact us to discuss how we can assist with your logistics needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>What types of goods are suitable for rail freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "260px" : "0px" }}
                            >
                                <p>
                                    Rail freight is ideal for transporting bulk commodities, pallets, heavy machinery, and oversized items. It’s also well-suited for specialised cargo requiring secure and efficient handling.
                                    <br />
                                    Contact us to confirm whether your goods are compatible with our rail freight services.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>How does rail freight compare to road freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Rail freight offers advantages such as cost-effectiveness for large volumes, reduced environmental impact, and fewer delays due to traffic. It is particularly beneficial for long-haul transport between Melbourne, Brisbane, Sydney, and Perth.
                                    <br />
                                    Road freight, on the other hand, is often preferred for shorter distances and last-mile deliveries.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Can I track my shipments in real time?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, all rail freight shipments are equipped with real-time tracking capabilities. You’ll receive regular updates, ensuring full visibility from dispatch to delivery.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>What are the weight and size limits for rail freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "250px" : "0px" }}
                            >
                                <p>
                                    Rail freight can accommodate a wide range of weights and sizes, including oversized and bulk shipments. Specific limitations depend on the type of goods and route.
                                    <br />
                                    Contact us with your shipment details, and our team will provide tailored guidance.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I get a quote for rail freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "230px" : "0px" }}
                            >
                                <p>
                                    To request a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page and provide details such as origin and destination, type of goods, dimensions, and weight.
                                    <br />
                                    Our logistics experts will assess your requirements and provide a competitive, tailored quote promptly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default RailFreight;