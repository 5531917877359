import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Pallet() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Pallet Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="Quan Freight Management provides specialised pallet freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective logistics solutions ensure the safe and timely transport of palletised goods using advanced tracking and expert handling." />
                <meta name="keywords" content="Pallet Freight Services, Quan Freight Management, QFM Logistics, Pallet Transport Australia, Melbourne Pallet Freight, Brisbane Pallet Logistics, Freight Solutions, Reliable Logistics, Road Freight, Rail Freight, Air Freight, Pallet Delivery, Palletised Goods Transport, Cost-Effective Freight Services, Safe Freight Handling, Advanced Logistics Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Pallet Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's pallet freight services. Serving Melbourne, Brisbane, and Australia-wide, we ensure efficient and secure delivery of palletised goods with advanced logistics solutions and expert handling." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-pallet.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/pallet-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/pallet-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/pallet-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/pallet-freight-services",
                                "name": "Pallet Freight Services | Logistics Solutions by QFM",
                                "description": "QFM specialises in pallet freight services from Melbourne, providing efficient transport for general and specialised goods to major cities and regional areas across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/pallet-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/pallet-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Pallet Freight Services",
                                        "item": "https://qfmlogistics.com.au/pallet-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/pallet-freight-services#service",
                                "name": "Pallet Freight Services",
                                "description": "Our pallet freight services offer efficient and secure transport of general and specialised goods, with nationwide coverage extending from Melbourne to key Australian cities and regional hubs.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-pallet.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-pallet.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-pallet.jpg",
                                "description": "Efficient pallet freight services connecting Melbourne with major cities across Australia",
                                "name": "Pallet Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-pallet-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-pallet-two.jpg",
                                "description": "Specialised pallet freight handling and secure transport services across Australia",
                                "name": "Pallet Freight Services - Reliable Logistics Solutions",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/pallet-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which locations do you serve for pallet freight deliveries?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our pallet freight services provide coverage from Melbourne to major cities across Australia, including Sydney, Brisbane, Perth, and Hobart, as well as regional and remote areas."
                                        }
                                        ,
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/pallet-freight-services",
                                            "name": "Pallet Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of pallets can you transport?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We transport standard, oversized, and custom pallets. Our services cater to a variety of goods, from general freight to specialised materials like dangerous goods."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/pallet-freight-services",
                                            "name": "Pallet Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can you handle oversized or heavy pallets?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we have the expertise and resources to transport oversized and heavy pallets securely and efficiently, adhering to all safety regulations."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/pallet-freight-services",
                                            "name": "Pallet Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for pallet freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page at https://qfmlogistics.com.au/get-in-touch and provide your shipment details to receive a tailored quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/pallet-freight-services",
                                            "name": "Pallet Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you offer real-time tracking for pallet shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we provide real-time tracking for pallet shipments, allowing you to monitor progress from dispatch to delivery."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/pallet-freight-services",
                                            "name": "Pallet Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Pallet Freight Services</h1>
                        <p>
                            QFM specialises in providing efficient and secure pallet freight services across Australia, including key routes from Melbourne to Sydney. With our robust logistics network and advanced tracking technology, we ensure your palletised goods are handled with care and delivered on time. Whether you require urgent transport or scalable logistics solutions, our services are designed to meet your unique business needs while maximising cost-efficiency.
                        </p>
                        {!isMobile && (
                            <p>
                                Our pallet freight services extend beyond Brisbane to all major Australian cities, including Sydney, Perth, Adelaide, Darwin, Canberra, Hobart, and regional areas. Leveraging diverse transport options—road, rail, and air—we offer seamless freight solutions tailored to your timelines and requirements.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="/images/qfm-item-types-pallet.jpg"
                            alt="Efficient pallet freight services ensuring safe and reliable transport across Melbourne, Brisbane, and Australia-wide"
                            title="Pallet Freight Services - Reliable logistics solutions by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Efficient and reliable pallet freight solutions tailored to your logistics needs</h5>
                        )}
                        <p>
                            From Melbourne, our pallet freight services extend seamlessly to major cities such as Sydney, Perth, Adelaide and Brisbane, as well as regional areas across Australia. <Link to="/types-of-freight">View all the types of freight we handle</Link> and discover how we can support your business.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Pallet Freight by QFM</h2>
                        <ul>
                            <li><b>Optimised Load Management:</b> Solutions designed to maximise pallet capacity and ensure cost-effective transport.</li>
                            <li><b>Consistent Reliability:</b> Dependable services meeting delivery schedules for pallet freight across Australia.</li>
                            <li><b>Secure Handling:</b> Robust protocols to keep palletised goods safe and intact during transit.</li>
                            <li><b>Extensive Network:</b> Seamless connections from Melbourne to major cities like Sydney, Brisbane, Perth, and Hobart.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="/images/qfm-item-types-pallet-two.jpg"
                            loading="lazy"
                            alt="Efficient pallet freight services connecting Melbourne to cities across Australia"
                            title="Pallet Freight Services – Reliable Logistics Solutions by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="pallet-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which locations do you serve for pallet freight deliveries?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our pallet freight services cover Melbourne and extend across Australia, including major cities like Sydney, Brisbane, Perth, Adelaide, and Hobart. We also deliver to regional and remote areas, offering comprehensive nationwide coverage.
                                    <br />
                                    No matter your destination, we ensure your goods are delivered securely and on time.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>What pallet sizes and types can you handle?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "260px" : "0px" }}
                            >
                                <p>
                                    We handle standard pallets, oversized pallets, and custom pallet configurations. Whether your goods require Euro pallets, CHEP pallets, or custom dimensions, we have the capacity and expertise to transport them efficiently.
                                    <br />
                                    Contact us with your pallet specifications for more tailored solutions.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What types of goods can you transport on pallets?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "270px" : "0px" }}
                            >
                                <p>
                                    We transport a diverse range of goods, including general freight, retail products, machinery, and agricultural supplies. Additionally, we cater to the transport of specialised items like fragile goods and dangerous goods, adhering to all safety and compliance standards.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>How can I request a quote for pallet freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is easy. Simply visit our <Link to="/get-in-touch">Get In Touch</Link> page and provide the following details: pickup and delivery locations, pallet dimensions, weight, type of goods, and any special requirements.
                                    <br />
                                    Our team will respond promptly with a tailored, competitive quote to suit your freight needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Do you handle dangerous goods on pallets?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "260px" : "0px" }}
                            >
                                <p>
                                    Yes, we provide services for transporting dangerous goods on pallets. Our team ensures compliance with all relevant regulations, using specialised handling and secure transport practices to protect your shipment and its surroundings.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>What industries benefit from pallet freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "260px" : "0px" }}
                            >
                                <p>
                                    Our pallet freight services support industries including retail, manufacturing, agriculture, construction, and healthcare. We tailor logistics solutions to meet the specific needs of each sector, ensuring seamless transport from origin to destination.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                 {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Pallet;


