import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Drum() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Drum Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides specialised drum freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective logistics solutions ensure the safe and efficient transport of drums containing liquids, chemicals, or industrial materials, with advanced tracking and expert handling." />
                <meta name="keywords" content="Drum Freight Services, Quan Freight Management, QFM Logistics, Drum Transport Australia, Melbourne Drum Freight, Brisbane Drum Logistics, Freight Solutions, Reliable Logistics, Road Freight, Rail Freight, Air Freight, Drum Delivery, Industrial Drum Transport, Safe Freight Handling, Hazardous Goods Logistics, Cost-Effective Logistics Solutions, Advanced Freight Technology" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Drum Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's drum freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide efficient and secure transport for industrial drums, including liquids and chemicals, with advanced logistics solutions and professional handling." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-drum.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/drum-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/drum-freight-services" />
                {/* NEED TO UPDATE THE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/drum-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/drum-freight-services",
                                "name": "Drum Freight Services | Logistics Solutions by QFM",
                                "description": "QFM specialises in drum freight services from Melbourne, offering secure and efficient transport for industrial, hazardous, and bulk drum shipments across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/drum-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/drum-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Drum Freight Services",
                                        "item": "https://qfmlogistics.com.au/drum-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/drum-freight-services#service",
                                "name": "Drum Freight Services",
                                "description": "We provide specialised drum freight services for industrial, hazardous, and bulk drum shipments, ensuring secure handling and compliance with safety regulations.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-drum.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-drum.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-drum.jpg",
                                "description": "Professional drum freight handling for industrial and hazardous materials.",
                                "name": "Drum Freight Services - Secure Logistics by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-drum-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-drum-two.jpg",
                                "description": "Specialised logistics for secure drum freight transport across Australia.",
                                "name": "Drum Freight Logistics - Expert Handling by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/drum-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What types of drums can QFM handle?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "QFM handles a wide range of drums, including plastic, steel, and fibre drums. We are equipped to transport both standard-sized and custom drums securely."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/drum-freight-services",
                                            "name": "Drum Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are you equipped to transport hazardous materials in drums?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we specialise in handling hazardous materials stored in drums. Our team follows all Dangerous Goods regulations to ensure safe and compliant transport."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/drum-freight-services",
                                            "name": "Drum Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can you manage bulk drum shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, QFM is equipped to handle bulk drum shipments efficiently, catering to industries such as chemicals, manufacturing, and agriculture."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/drum-freight-services",
                                            "name": "Drum Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there size or weight restrictions for drums?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "While we handle drums of various sizes and weights, specific restrictions may apply based on transport mode. Contact us with your drum details for tailored guidance."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/drum-freight-services",
                                            "name": "Drum Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you provide tracking for drum shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, all drum shipments include real-time tracking, allowing you to monitor progress from pickup to delivery."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/drum-freight-services",
                                            "name": "Drum Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do I request a quote for drum freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page and provide details such as drum type, dimensions, weight, and pickup and delivery locations. Our team will promptly provide a customised quote."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/drum-freight-services",
                                            "name": "Drum Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Drum Freight Services</h1>
                        <p>
                            Safeguarding liquid and chemical cargo demands meticulous planning, and that’s exactly what our drum freight services deliver. We go beyond simple loading and transport—every step is optimised to maintain safety, regulatory compliance, and efficiency. Through specialised equipment, rigorous handling protocols, and real-time tracking, we ensure that every drum shipment arrives on schedule.
                        </p>
                        {!isMobile && (
                            <p>
                                From Melbourne to every corner of Australia, our drum logistics solutions connect major cities like Sydney, Brisbane, Perth, and Adelaide, along with regional areas. By blending road, rail, and air transport, we ensure your shipments stay on schedule and reach their destination intact.
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="/images/qfm-item-types-drum.jpg"
                            alt="Reliable drum freight services for liquids, chemicals, and industrial materials across Australia"
                            title="Drum Freight Services – Comprehensive Logistics by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Specialised drum transport backed by expert handling and timely delivery</h5>
                        )}
                        <p>
                            Covering routes from Melbourne to the country’s key logistic hubs, our drum freight services cater to complex requirements with precision. Explore our tailored solutions to experience dependable and worry‑free shipping. <Link to="/types-of-freight">Find out about the full selection of freight options available</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content sydney-add appearing-effect">
                        <h2>Melbourne Drum Freight by QFM</h2>
                        <ul>
                            <li>
                                <b>High-Level Safety:</b> Our protocols for handling liquids, chemicals, and industrial materials prioritise protection for both freight and personnel.
                            </li>
                            <li>
                                <b>Reliable Schedules:</b> Efficient coordination ensures your drums arrive when and where they need to, minimising downtime.
                            </li>
                            <li>
                                <b>Regulatory Compliance:</b> Each shipment meets local and national safety standards, streamlining the entire logistics process.
                            </li>
                            <li>
                                <b>Expansive Coverage:</b> From Melbourne to regional and metropolitan areas nationwide, we connect you with Australia’s essential routes.
                            </li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="/images/qfm-item-types-drum-two.jpg"
                            loading="lazy"
                            alt="Expert drum freight handling for industrial and hazardous materials with secure logistics solutions"
                            title="Melbourne Freight Handling – Reliable Drum Freight Services by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="drum-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What types of drums can QFM handle?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    QFM handles a wide range of drums, including plastic, steel, and fibre drums. We are equipped to transport both standard-sized drums and custom configurations, ensuring safety and compliance throughout the process.
                                    <br />
                                    For unique drum types, contact our team to discuss tailored logistics solutions.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Are you equipped to transport hazardous materials in drums?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "250px" : "0px" }}
                            >
                                <p>
                                    Yes, we specialise in handling hazardous materials securely. Our team is trained in Dangerous Goods regulations, ensuring safe transit of chemicals, fuels, or other hazardous substances stored in drums.
                                    <br />
                                    We prioritise compliance and safety with industry-standard protocols for every shipment.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>Can you manage bulk drum shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "250px" : "0px" }}
                            >
                                <p>
                                    Absolutely. QFM is equipped to handle bulk shipments of drums for industrial, manufacturing, or agricultural purposes. We optimise load management to ensure efficient and secure transportation.
                                    <br />
                                    Let us know your requirements for bulk shipping, and we’ll provide a tailored solution.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Are there specific size or weight limits for drums?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    While we accommodate a wide range of drum sizes and weights, some restrictions may apply based on transport modes and regulations. Contact us with your drum dimensions and weight, and we’ll recommend the best logistics solution.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>Do you provide tracking for drum shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, real-time tracking is available for all drum shipments. You can monitor your freight at every stage, from pickup to delivery, ensuring complete transparency and peace of mind.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>What industries benefit from drum freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "280px" : "0px" }}
                            >
                                <p>
                                    Our drum freight services cater to industries like manufacturing, chemicals, agriculture, construction, and energy. Whether you’re shipping industrial materials or agricultural products, our solutions are tailored to your needs.
                                    <br />
                                    Reach out to explore how we can support your industry-specific requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div
                            className={`faq-item ${openFAQ === 6 ? "active" : ""}`}
                            onClick={() => toggleFAQ(6)}
                        >
                            <h5>How do I request a quote for drum freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 6 ? "250px" : "0px" }}
                            >
                                <p>
                                    To request a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page and provide details like the type of drums, dimensions, weight, and pickup and delivery locations.
                                    <br />
                                    Our team will assess your needs and provide a competitive, customised quote promptly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Drum;


