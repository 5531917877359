import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Stillage() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);
    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };
    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Stillage Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM provides expert stillage freight services across Melbourne, Brisbane, and Australia-wide. Our reliable and cost-effective solutions ensure the safe and timely transport of framed and caged goods with advanced tracking and professional handling." />
                <meta name="keywords" content="Stillage Freight Services, Quan Freight Management, QFM Logistics, Stillage Transport Australia, Melbourne Stillage Freight, Brisbane Stillage Logistics, Freight Solutions, Reliable Logistics, Road Freight, Rail Freight, Air Freight, Stillage Delivery, Framed Goods Transport, Cost-Effective Logistics Solutions, Advanced Freight Handling" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Stillage Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Explore QFM's stillage freight services. Serving Melbourne, Brisbane, and Australia-wide, we provide efficient and secure transport for framed and caged goods with advanced logistics solutions and expert care." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-stillage.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/stillage-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/stillage-freight-services" />
                {/* NEED TO UPDATE THE JSON */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/stillage-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/stillage-freight-services",
                                "name": "Stillage Freight Services | Expert Logistics Solutions by QFM",
                                "description": "QFM provides reliable stillage freight services, connecting Melbourne with major cities and regional locations across Australia. We specialise in secure transport of framed and caged shipments.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/stillage-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/stillage-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Stillage Freight Services",
                                        "item": "https://qfmlogistics.com.au/stillage-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/stillage-freight-services#service",
                                "name": "Stillage Freight Services",
                                "description": "Secure transport of framed and caged shipments across Australia, connecting Melbourne to key logistics hubs and regional locations.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-stillage.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-stillage.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-stillage.jpg",
                                "description": "Reliable stillage freight services connecting Melbourne to key locations across Australia.",
                                "name": "Stillage Freight Services - Secure Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-stillage-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-stillage-two.jpg",
                                "description": "Expert stillage freight handling for secure transport of framed and caged shipments across Australia.",
                                "name": "Stillage Freight Logistics - Reliable Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/stillage-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "What locations do your stillage freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our stillage freight services operate from Melbourne and extend to major cities such as Sydney, Brisbane, Perth, and Adelaide, as well as regional and remote locations across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/stillage-freight-services",
                                            "name": "Stillage Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Can you handle fragile or high-value stillage shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, we specialise in managing fragile and high-value framed or caged shipments, with secure handling and tailored solutions to ensure safe transit."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/stillage-freight-services",
                                            "name": "Stillage Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Are there size or weight restrictions for stillage freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We accommodate a wide range of stillage sizes and weights. Contact our team with your specific shipment details to determine the best logistics solution."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/stillage-freight-services",
                                            "name": "Stillage Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What types of goods can be transported in stillages?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Stillage freight is ideal for framed or caged goods, including industrial equipment, fragile items, and oversized shipments."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/stillage-freight-services",
                                            "name": "Stillage Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for stillage freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page at https://qfmlogistics.com.au/get-in-touch, provide shipment details such as dimensions, weight, and destination, and we’ll provide a tailored quote promptly."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/stillage-freight-services",
                                            "name": "Stillage Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Stillage Freight Services</h1>
                        <p>
                            Transporting framed and caged goods requires more than just logistics—it demands expertise, precision, and a commitment to security. At QFM, our stillage freight services are meticulously designed to ensure every shipment is handled with unparalleled care, safeguarding your valuable cargo from start to finish. 

                        </p>
                        {!isMobile && (
                            <p>
                                Based in Melbourne, we connect your stillage freight to Australia’s key logistics hubs, including Sydney, Brisbane, Perth, and Adelaide. Our tailored solutions leverage advanced handling protocols and versatile transport options to provide seamless, reliable delivery to both urban centres and regional locations nationwide. 
                            </p>
                        )}
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="/images/qfm-item-types-stillage.jpg"
                            alt="Robust stillage freight services ensuring secure transport of framed and caged shipments across Australia"
                            title="Stillage Freight Services – Secure and Reliable Logistics by QFM"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Customised stillage freight solutions crafted for secure, on‑time delivery</h5>
                        )}
                        <p>
                            Our stillage freight services from Melbourne ensure seamless connectivity to major hubs, offering reliable and timely transport of framed, caged shipments across Australia. <Link to="/types-of-freight">Check out the full list of freight services we handle</Link>.
                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Stillage Freight by QFM</h2>
                        <ul>
                            <li><b>Specialised Handling:</b> Purpose-built solutions to securely manage framed and caged loads, safeguarding your shipments throughout transit.</li>
                            <li><b>Consistent Reliability:</b> Dependable delivery that ensures your stillage shipments arrive on time, every time.</li>
                            <li><b>Enhanced Protection:</b> Advanced packaging and handling techniques that minimise risk and damage to your valuable goods.</li>
                            <li><b>Nationwide Network:</b> Comprehensive coverage from Melbourne to all major Australian cities and regional centres, ensuring seamless logistics.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="/images/qfm-item-types-stillage-two.jpg"
                            loading="lazy"
                            alt="Expert stillage freight handling for secure transport of framed and caged shipments across Australia"
                            title="Melbourne Freight Handling – Reliable Stillage Freight Services by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                                <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="stillage-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>What is stillage freight, and when is it used?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "300px" : "0px" }}
                            >
                                <p>
                                    Stillage freight involves the transport of framed or caged structures used to protect and secure items during transit. It is ideal for goods that require additional stability, such as machinery, delicate components, or stacked products.
                                    <br />
                                    Stillages are widely used in industries like construction, manufacturing, and logistics for their ability to safeguard goods and simplify loading and unloading.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Which areas do you serve for stillage freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "300px" : "0px" }}
                            >
                                <p>
                                    Our stillage freight services connect Melbourne to all major Australian cities, including Sydney, Brisbane, Perth, and Adelaide. We also deliver to regional and remote locations, ensuring complete national coverage for your logistics needs.
                                    <br />
                                    No matter the destination, we ensure secure and efficient delivery tailored to your requirements.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>What types of goods are suitable for stillage freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "260px" : "0px" }}
                            >
                                <p>
                                    Stillage freight is ideal for transporting goods that need extra stability and protection, such as construction materials, metal products, industrial machinery, and stacked items. The secure framing ensures the safe handling of fragile, heavy, or irregularly shaped cargo.
                                    <br />
                                    Reach out to discuss your specific freight requirements, and we’ll confirm if stillage freight is the right fit for your shipment.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>Are there size or weight restrictions for stillages?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "250px" : "0px" }}
                            >
                                <p>
                                    We accommodate stillages of various sizes and weights, including oversized and heavy loads. Specific restrictions may apply depending on transport modes and regulations. Our team will work with you to assess your shipment and recommend the best solution for safe and compliant delivery.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 5 */}
                        <div
                            className={`faq-item ${openFAQ === 4 ? "active" : ""}`}
                            onClick={() => toggleFAQ(4)}
                        >
                            <h5>What industries benefit from stillage freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 4 ? "280px" : "0px" }}
                            >
                                <p>
                                    Stillage freight is commonly used in industries such as construction, manufacturing, and retail. It is particularly beneficial for transporting fragile or heavy items that need secure framing to avoid damage during transit.
                                    <br />
                                    Whether you’re moving industrial equipment or bulk materials, our stillage freight solutions cater to diverse industrial needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I request a quote for stillage freight?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "250px" : "0px" }}
                            >
                                <p>
                                    To request a quote, visit our <Link to="/get-in-touch">Contact Us</Link> page and share details such as the dimensions, weight, and destination of your shipment. Include any special handling requirements.
                                    <br />
                                    Our team will promptly provide a tailored and competitive quote designed to meet your specific logistics needs.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 7 */}
                        <div
                            className={`faq-item ${openFAQ === 6 ? "active" : ""}`}
                            onClick={() => toggleFAQ(6)}
                        >
                            <h5>Can I monitor my stillage shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 6 ? "230px" : "0px" }}
                            >
                                <p>
                                    Yes, we provide tracking options for stillage shipments to keep you updated throughout the delivery process. From pickup to final delivery, you’ll receive notifications at key milestones for complete peace of mind.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Stillage;


