import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import '../css/ServicesMelbourne.css';
import { Link } from 'react-router-dom';

function Semi() {
    const [isVisible, setIsVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isScrollUpVisible, setIsScrollUpVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          setIsScrollUpVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) return; // skip for mobile if not desired
        const handleScroll = () => {
            if (window.scrollY === 0) {
                document.body.classList.add('hide-scrollbar');
            } else {
                document.body.classList.remove('hide-scrollbar');
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll(); // initial check
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isMobile]);

    const scrollToTop = () => {
        if (isMobile) return; // skip for mobile if we don't want the scroll-up
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (isMobile) return; // skip for mobile
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 300);
        };
        window.addEventListener('scroll', toggleVisibility);
        toggleVisibility(); // initial
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, [isMobile]);

    useEffect(() => {
        const sections = document.querySelectorAll('.appearing-effect');
        const observerOptions = { threshold: 0.15 };
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        }, observerOptions);

        sections.forEach((section) => observer.observe(section));
        return () => sections.forEach((section) => observer.unobserve(section));
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [openFAQ, setOpenFAQ] = useState(null);
    useEffect(() => {
        const servicesLink = document.querySelector('a[href="/types-of-freight"]');
        if (servicesLink) {
            servicesLink.classList.add('active');
        }
        return () => {
            if (servicesLink) {
                servicesLink.classList.remove('active');
            }
        };
    }, []);
    const toggleFAQ = (index) => {
        if (openFAQ === index) {
            setOpenFAQ(null);
        } else {
            setOpenFAQ(null);
            setTimeout(() => setOpenFAQ(index), 100);
        }
    };

    return (
        <div className="brisbane-main-content">
            <Helmet>
                <title>Semi Freight Services | Logistics Solutions by QFM</title>
                <meta name="description" content="QFM specialises in reliable Semi Freight services across Melbourne, Brisbane, and Australia-wide. Our efficient and cost-effective logistics solutions ensure the safe and timely transport of large shipments with advanced tracking and professional handling." />
                <meta name="keywords" content="Semi Freight Services, Semi-Trailer Freight, Quan Freight Management, QFM Logistics, Large Shipment Transport, Melbourne Semi Freight, Brisbane Semi Logistics, Freight Solutions, Reliable Logistics, Road Freight, Heavy Haulage, Long-Haul Freight, Advanced Freight Technology, Safe Freight Handling, Cost-Effective Logistics Solutions" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
                <meta name="geo.region" content="AU-VIC" />
                <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
                <meta name="geo.position" content="-37.8136;144.9631" />
                <meta property="og:title" content="Semi Freight Services | Logistics Solutions by QFM" />
                <meta property="og:description" content="Discover QFM's Semi Freight services. Serving Melbourne, Brisbane, and Australia-wide, we deliver efficient and secure transport of large shipments with advanced logistics solutions and expert care." />
                <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-item-types-semi.jpg" />
                <meta property="og:url" content="https://qfmlogistics.com.au/semi-freight-services" />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_AU" />
                <meta property="og:site_name" content="Quan Freight Management" />
                <link rel="canonical" href="https://qfmlogistics.com.au/semi-freight-services" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@graph": [
                            {
                                "@type": "WebPage",
                                "@id": "https://qfmlogistics.com.au/semi-freight-services#webpage",
                                "url": "https://qfmlogistics.com.au/semi-freight-services",
                                "name": "Semi Freight Services | Logistics Solutions by QFM",
                                "description": "QFM specialises in semi freight services from Melbourne, offering direct, efficient transport of large shipments to major hubs across Australia.",
                                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                                "inLanguage": "en-AU",
                                "breadcrumb": { "@id": "https://qfmlogistics.com.au/semi-freight-services#breadcrumb" }
                            },
                            {
                                "@type": "Organization",
                                "@id": "https://qfmlogistics.com.au#organization",
                                "name": "Quan Freight Management",
                                "url": "https://qfmlogistics.com.au",
                                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                                "contactPoint": {
                                    "@type": "ContactPoint",
                                    "email": "contact@qfmlogistics.com.au",
                                    "contactType": "Customer Service",
                                    "areaServed": "AU"
                                },
                                "sameAs": [
                                    "https://www.linkedin.com/company/qfm-logistics"
                                ]
                            },
                            {
                                "@type": "BreadcrumbList",
                                "@id": "https://qfmlogistics.com.au/semi-freight-services#breadcrumb",
                                "itemListElement": [
                                    {
                                        "@type": "ListItem",
                                        "position": 1,
                                        "name": "Home",
                                        "item": "https://qfmlogistics.com.au/"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 2,
                                        "name": "Types of Freight",
                                        "item": "https://qfmlogistics.com.au/types-of-freight"
                                    },
                                    {
                                        "@type": "ListItem",
                                        "position": 3,
                                        "name": "Semi Freight Services",
                                        "item": "https://qfmlogistics.com.au/semi-freight-services"
                                    }
                                ]
                            },
                            {
                                "@type": "Service",
                                "@id": "https://qfmlogistics.com.au/semi-freight-services#service",
                                "name": "Semi Freight Services",
                                "description": "Our semi freight services are engineered for efficient, secure transport of large loads. Based in Melbourne, we offer direct routes to Australia’s key logistics hubs—including Sydney, Brisbane, Perth, and Adelaide.",
                                "provider": { "@id": "https://qfmlogistics.com.au#organization" },
                                "areaServed": "Australia",
                                "availableChannel": {
                                    "@type": "ServiceChannel",
                                    "serviceUrl": "https://qfmlogistics.com.au/get-in-touch"
                                },
                                "image": "https://qfmlogistics.com.au/images/qfm-item-types-semi.jpg"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-semi.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-semi.jpg",
                                "description": "Efficient semi freight services connecting Melbourne with major cities across Australia",
                                "name": "Semi Freight Services - Logistics Solutions by QFM",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "ImageObject",
                                "contentUrl": "https://qfmlogistics.com.au/images/qfm-item-types-semi-two.jpg",
                                "url": "https://qfmlogistics.com.au/images/qfm-item-types-semi-two.jpg",
                                "description": "Expert semi freight handling and efficient heavy load transport across Australia",
                                "name": "Semi Freight Services - Reliable Logistics Solutions",
                                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                                "license": { "@id": "https://qfmlogistics.com.au#license" },
                                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                                "copyrightNotice": "© Quan Freight Management",
                                "creditText": "Quan Freight Management",
                                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
                            },
                            {
                                "@type": "FAQPage",
                                "@id": "https://qfmlogistics.com.au/semi-freight-services#faq",
                                "mainEntity": [
                                    {
                                        "@type": "Question",
                                        "name": "Which regions do your semi freight services cover?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Our semi freight network, based in Melbourne, offers direct connectivity to major cities such as Sydney, Brisbane, Perth, and Adelaide, along with strategic regional centres across Australia."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/semi-freight-services",
                                            "name": "Semi Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "Do you offer direct delivery routes for semi freight?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Yes, our semi freight services use direct, efficient routes with minimal transit stops, ensuring your shipments are delivered quickly and reliably."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/semi-freight-services",
                                            "name": "Semi Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How do you ensure the safety and integrity of semi freight shipments?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "We prioritise safety with rigorous handling protocols, specialised equipment, and strict adherence to regulatory standards."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/semi-freight-services",
                                            "name": "Semi Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "What factors influence the pricing of semi freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Pricing depends on distance, load weight, volume, and route complexity. We provide tailored quotes based on your shipment details."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/semi-freight-services",
                                            "name": "Semi Freight Services FAQ"
                                        }
                                    },
                                    {
                                        "@type": "Question",
                                        "name": "How can I request a quote for your semi freight services?",
                                        "acceptedAnswer": {
                                            "@type": "Answer",
                                            "text": "Visit our Contact Us page at https://qfmlogistics.com.au/get-in-touch, provide shipment details, and receive a tailored quote promptly."
                                        },
                                        "isPartOf": {
                                            "@type": "WebPage",
                                            "@id": "https://qfmlogistics.com.au/semi-freight-services",
                                            "name": "Semi Freight Services FAQ"
                                        }
                                    }
                                ]
                            }
                        ]
                    })}
                </script>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
                <script>
                    {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
                </script>
            </Helmet>
            <div className="ourservices-content-container">
                <Header isScrolledInitially={true} />
                {!isMobile && (
                    <div className="vertical-line"></div>
                )}
                <div className="ourstory-intro-section technology-intro-section-add appearing-effect">
                    <div className="melbourne-intro-section-text-content">
                        <h1>Semi Freight Services</h1>
                        {!isMobile && (
                        <p>
                            At QFM, we specialise in delivering high‑volume semi freight solutions designed for the transport of large loads with unmatched efficiency. Our semi freight services combine expert handling with robust logistics strategies to ensure that your heavy shipments are managed safely and delivered on schedule.
                        </p>
                        )}
                        <p>
                            From Melbourne, our semi freight network connects seamlessly with major hubs—such as Sydney, Brisbane, Perth, and Adelaide—as well as key regional centres. Using a combination of road, rail, and air transport options, we provide flexible, cost‑effective solutions that adapt to tight schedules and varying load requirements.
                        </p>
                    </div>
                    <div className="ourstory-intro-section-image-container">
                        <img
                            src="/images/qfm-item-types-semi.jpg"
                            alt="Efficient semi freight services connecting Melbourne with major cities across Australia"
                            title="Semi Freight Services – Expert Heavy Load Logistics by Quan Freight Management"
                            loading="lazy"
                        />
                    </div>
                </div>
                <div className="default-quote appearing-effect">
                    <div className="default-quote-quote">
                        <Link to="/get-in-touch"><h4>Request a Quote</h4></Link>
                    </div>
                    <div className="default-quote-text-content">
                        {!isMobile && (
                            <h5>Customised semi freight solutions engineered for efficient and secure heavy load transport</h5>
                        )}
                        <p>
                            Our semi freight services from Melbourne ensure direct connectivity to pivotal logistics hubs including Sydney, Brisbane, Perth, and Adelaide, making certain that your large-scale shipments move swiftly and reliably across Australia. <Link to="/types-of-freight">See our extensive freight options in detail</Link>.

                        </p>
                    </div>
                </div>
                <div className="default-section-content-left appearing-effect">
                    <div className="default-section-content-left-text-content appearing-effect sydney-add">
                        <h2>Melbourne Semi Freight by QFM</h2>
                        <ul>
                            <li><b>Optimised Load Management:</b> Customised solutions that maximise capacity and efficiency for heavy, large-volume shipments.</li>
                            <li><b>Consistent Reliability:</b> On‑time, dependable delivery services designed for long-haul routes and high-volume transport.</li>
                            <li><b>Robust Handling:</b> Advanced protocols and specialised equipment ensure secure handling of heavy freight throughout transit.</li>
                            <li><b>Extensive Network:</b> Comprehensive reach from Melbourne to all major Australian cities and regional hubs, facilitating seamless nationwide transport.</li>
                        </ul>
                    </div>
                    <div className="default-section-content-left-image-container appearing-effect">
                        <img
                            src="/images/qfm-item-types-semi-two.jpg"
                            loading="lazy"
                            alt="Expert semi freight handling and efficient heavy load transport across Australia"
                            title="Melbourne Freight Handling – Reliable Semi Freight Logistics by QFM"
                        />
                         {isMobile && (
                        <div className="default-quote appearing-effect">
                            <div className="default-quote-quote">
                            <Link to="/types-of-freight"><h4>View all Item Types</h4></Link>
                            </div>
                        </div>
                    )}
                    </div>
                </div>
                <div className="semi-faq-section appearing-effect">
                    <div className="faq-text-content">
                        <h2>FAQ</h2>

                        {/* FAQ 1 */}
                        <div
                            className={`faq-item ${openFAQ === 0 ? "active" : ""}`}
                            onClick={() => toggleFAQ(0)}
                        >
                            <h5>Which regions do your semi freight services cover?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 0 ? "320px" : "0px" }}
                            >
                                <p>
                                    Our semi freight services are based in Melbourne and extend directly to Australia’s primary logistics hubs—such as Sydney, Brisbane, Perth, and Adelaide—as well as numerous strategic regional centres. This broad network ensures that even if your shipment is destined for a remote or rural area, we have the infrastructure and expertise to deliver it safely and efficiently.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 2 */}
                        <div
                            className={`faq-item ${openFAQ === 1 ? "active" : ""}`}
                            onClick={() => toggleFAQ(1)}
                        >
                            <h5>Do you offer direct delivery routes for semi freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 1 ? "270px" : "0px" }}
                            >
                                <p>
                                    Yes, our semi freight services are designed around direct delivery routes that minimize transit stops. We carefully plan routes to avoid unnecessary delays, ensuring that your heavy shipments are transported on a streamlined path from pickup to destination. This results in faster delivery times and enhanced efficiency for large-scale shipments.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 3 */}
                        <div
                            className={`faq-item ${openFAQ === 2 ? "active" : ""}`}
                            onClick={() => toggleFAQ(2)}
                        >
                            <h5>How do you ensure the safety and integrity of heavy semi freight shipments?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 2 ? "290px" : "0px" }}
                            >
                                <p>
                                    Safety is paramount in our semi freight operations. We employ rigorous safety protocols and cutting-edge load securing techniques to protect your shipments throughout the journey. Our team is trained in best practices for handling heavy loads, and we use specialised equipment to secure cargo. Regular maintenance of our vehicles and adherence to regulatory standards further ensures that every shipment is transported in optimal condition.
                                </p>
                            </div>
                        </div>

                        {/* FAQ 4 */}
                        <div
                            className={`faq-item ${openFAQ === 3 ? "active" : ""}`}
                            onClick={() => toggleFAQ(3)}
                        >
                            <h5>What factors influence the cost of semi freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 3 ? "280px" : "0px" }}
                            >
                                <p>
                                    The cost of our semi freight services is determined by several factors, including distance, load weight and volume, route complexity, and any additional handling or specialised service requirements. We take a customised approach to quoting, ensuring that every estimate is transparent and tailored to your specific shipment details. This means you get a competitive price that reflects both efficiency and quality service.
                                </p>
                            </div>
                        </div>                       

                        {/* FAQ 6 */}
                        <div
                            className={`faq-item ${openFAQ === 5 ? "active" : ""}`}
                            onClick={() => toggleFAQ(5)}
                        >
                            <h5>How can I request a quote for your semi freight services?</h5>
                            <div
                                className="faq-content"
                                style={{ maxHeight: openFAQ === 5 ? "270px" : "0px" }}
                            >
                                <p>
                                    Requesting a quote is straightforward. Simply visit our <Link to="/get-in-touch">Contact Us</Link> page and provide us with key details such as the pickup and delivery locations, dimensions, weight of the load, and any special handling requirements. Our logistics experts will review your information and respond quickly with a tailored, transparent quote designed specifically for your semi freight needs.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {isVisible && (
                    <div className="scroll-up" onClick={scrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                 {isMobile && isScrollUpVisible && (
                    <div className="scroll-up-mobile" onClick={handleScrollToTop}>
                        <i className="fas fa-chevron-up"></i>
                    </div>
                )}
                <Footer />
            </div>
        </div >
    );
}

export default Semi;


