import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from './Header';
import Footer from './Footer';
import CustomAlert from './CustomAlert';
import '../css/GetInTouch.css';
import emailjs from 'emailjs-com';

function GetInTouch() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile) return; // skip for mobile if not desired
    const handleScroll = () => {
      if (window.scrollY === 0) {
        document.body.classList.add('hide-scrollbar');
      } else {
        document.body.classList.remove('hide-scrollbar');
      }
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isMobile]);

  const scrollToTop = () => {
    if (isMobile) return; // skip for mobile if we don't want the scroll-up
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (isMobile) return; // skip for mobile
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 300);
    };
    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // initial
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [isMobile]);

  useEffect(() => {
    const sections = document.querySelectorAll('.appearing-effect');
    const observerOptions = { threshold: 0.15 };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, observerOptions);

    sections.forEach((section) => observer.observe(section));
    return () => sections.forEach((section) => observer.unobserve(section));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // State for form type (info vs. quote)
  const [selectedForm, setSelectedForm] = useState('information');
  const [quoteStep, setQuoteStep] = useState(1);

  // Info form states
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    remarks: '',
  });

  // Quote form states
  const [quoteFormValues, setQuoteFormValues] = useState({
    name: '',
    company: '',
    address: '',
    email: '',
    phone: '',
    pickupDestination: '',
    deliveryLocation: '',
    itemType: '',
    weight: '',
    quantity: '',
    length: '',
    width: '',
    height: '',
  });

  const [quoteFormErrors, setQuoteFormErrors] = useState({
    name: '',
    company: '',
    address: '',
    email: '',
    phone: '',
    pickupDestination: '',
    deliveryLocation: '',
    itemType: '',
    weight: '',
    quantity: '',
    length: '',
    width: '',
    height: '',
  });

  const handleRadioChange = (event) => {
    setSelectedForm(event.target.value);
    setQuoteStep(1);
  };

  // Quote steps
  const handlePreviousStep = (event) => {
    event.preventDefault();
    setQuoteStep((prevStep) => prevStep - 1);
  };

  // Focus handlers to clear errors
  const handleInputFocus = (event) => {
    const { name } = event.target;
    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  const handleQuoteInputFocus = (event) => {
    const { name } = event.target;
    setQuoteFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };

  // Info form input changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'remarks' && value.length > 279) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        remarks: 'Maximum 280 Characters',
      }));
    } else {
      setFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  // Quote form input changes
  const handleQuoteInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setQuoteFormErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));

    setQuoteFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  // Step 1 validation for Quote
  const validateQuoteStep1 = () => {
    const errors = {};
    if (!quoteFormValues.name) errors.name = 'Required';
    if (!quoteFormValues.email) {
      errors.email = 'Required';
    } else if (!validateEmail(quoteFormValues.email)) {
      errors.email = 'Must be a valid email';
    }
    if (!quoteFormValues.phone) {
      errors.phone = 'Required';
    } else if (!validatePhone(quoteFormValues.phone)) {
      errors.phone = 'Must be a valid number';
    }
    setQuoteFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleQuoteNextStep = (event) => {
    event.preventDefault();
    if (quoteStep === 1) {
      if (validateQuoteStep1()) {
        setQuoteStep((prevStep) => prevStep + 1);
      }
    } else if (quoteStep === 2) {
      if (validateQuoteStep2()) {
        setQuoteStep((prevStep) => prevStep + 1);
      }
    }
  };

  // Step 2 validation for Quote
  const validateQuoteStep2 = () => {
    const errors = {};
    if (!quoteFormValues.pickupDestination) errors.pickupDestination = 'Required';
    if (!quoteFormValues.deliveryLocation) errors.deliveryLocation = 'Required';
    if (!quoteFormValues.itemType) errors.itemType = 'Required';
    if (!quoteFormValues.weight) {
      errors.weight = 'Required';
    } else if (isNaN(quoteFormValues.weight)) {
      errors.weight = 'Must be a valid number';
    }
    if (!quoteFormValues.quantity) {
      errors.quantity = 'Required';
    } else if (isNaN(quoteFormValues.quantity)) {
      errors.quantity = 'Must be a valid number';
    }
    if (!quoteFormValues.length) {
      errors.length = 'Required';
    } else if (isNaN(quoteFormValues.length)) {
      errors.length = 'Must be a valid number';
    }
    if (!quoteFormValues.width) {
      errors.width = 'Required';
    } else if (isNaN(quoteFormValues.width)) {
      errors.width = 'Must be a valid number';
    }
    if (!quoteFormValues.height) {
      errors.height = 'Required';
    } else if (isNaN(quoteFormValues.height)) {
      errors.height = 'Must be a valid number';
    }

    setQuoteFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Validation helpers
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const validatePhone = (phone) => {
    const phonePattern = /^\d+$/;
    return phonePattern.test(phone);
  };

  // Info form validation
  const validateForm = () => {
    const errors = {};
    if (!formValues.name) errors.name = 'Required';
    if (!formValues.email) {
      errors.email = 'Required';
    } else if (!validateEmail(formValues.email)) {
      errors.email = 'Must be a valid email';
    }
    if (!formValues.phone) {
      errors.phone = 'Required';
    } else if (!validatePhone(formValues.phone)) {
      errors.phone = 'Must be a valid number';
    }
    return errors;
  };

  // Alerts
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState(''); // 'success' or 'error'

  // Info form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const templateParams = {
        name: formValues.name,
        company: formValues.company,
        email: formValues.email,
        phone: formValues.phone,
        remarks: formValues.remarks,
      };

      emailjs.send('service_eqjos09', 'template_6v12fmu', templateParams, '6hHglwaeH0hQQmjcJ')
        .then(() => {
          setAlertTitle('Success');
          setAlertMessage('Thank you! Your information request has been submitted.');
          setAlertType('success');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);

          // Reset form
          setFormValues({
            name: '',
            company: '',
            email: '',
            phone: '',
            remarks: '',
          });
        })
        .catch(() => {
          setAlertTitle('Error');
          setAlertMessage('There was an issue submitting your request. Please try again later.');
          setAlertType('error');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);
        });
    }
  };

  // Quote form submit
  const handleQuoteSubmit = (event) => {
    event.preventDefault();
    const errors = validateQuoteStep1();
    setQuoteFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const quoteTemplateParams = {
        name: quoteFormValues.name,
        company: quoteFormValues.company,
        address: quoteFormValues.address,
        email: quoteFormValues.email,
        phone: quoteFormValues.phone,
        pickupDestination: quoteFormValues.pickupDestination,
        deliveryLocation: quoteFormValues.deliveryLocation,
        itemType: quoteFormValues.itemType,
        weight: quoteFormValues.weight,
        quantity: quoteFormValues.quantity,
        length: quoteFormValues.length,
        width: quoteFormValues.width,
        height: quoteFormValues.height,
        hydraulicTailgate: quoteFormValues.hydraulicTailgate ? 'Yes' : 'No',
        residentialDelivery: quoteFormValues.residentialDelivery ? 'Yes' : 'No',
        poBoxDelivery: quoteFormValues.poBoxDelivery ? 'Yes' : 'No',
        residentialPickup: quoteFormValues.residentialPickup ? 'Yes' : 'No',
      };

      emailjs.send('service_eqjos09', 'template_1bmxjm7', quoteTemplateParams, '6hHglwaeH0hQQmjcJ')
        .then(() => {
          setAlertTitle('Success');
          setAlertType('success');
          setAlertMessage('Thank you! Your quote request has been submitted.');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);

          // Reset quote form
          setQuoteFormValues({
            name: '',
            company: '',
            address: '',
            email: '',
            phone: '',
            pickupDestination: '',
            deliveryLocation: '',
            itemType: '',
            weight: '',
            quantity: '',
            length: '',
            width: '',
            height: '',
            hydraulicTailgate: false,
            residentialDelivery: false,
            poBoxDelivery: false,
            residentialPickup: false,
          });
          setQuoteFormErrors({});
          setQuoteStep(1);
        })
        .catch(() => {
          setAlertTitle('Error');
          setAlertType('error');
          setAlertMessage('There was an issue submitting your quote request. Please try again later.');
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 4000);
        });
    } else {
      setAlertTitle('Error');
      setAlertType('error');
      setAlertMessage('Please correct the errors in the form and try again.');
      setAlertVisible(true);
      setTimeout(() => setAlertVisible(false), 4000);
    }
  };

  return (
    <div className="getintouch-main-content">
      <Helmet>
        <title>Get In Touch | Logistics Solutions by QFM</title>
        <meta
          name="description"
          content="Contact Quan Freight Management for expert freight solutions across Australia. From Melbourne to major cities, our dedicated team ensures seamless logistics with advanced technology and tailored services. Reach out to us today."
        />
        <meta
          name="keywords"
          content="Get In Touch, Contact QFM, Freight Solutions, Quan Freight Management, QFM Logistics, Melbourne Freight Services, Major Cities Freight, Seamless Logistics, Advanced Freight Technology, Tailored Freight Services, Freight Expertise, Reliable Freight Solutions, Australia-Wide Freight"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Quan Freight Management (https://qfmlogistics.com.au)" />
        <meta name="geo.region" content="AU-VIC" />
        <meta name="geo.placename" content="Melbourne, Victoria, Australia" />
        <meta name="geo.position" content="-37.8136;144.9631" />
        <meta property="og:title" content="Get In Touch | Logistics Solutions by QFM" />
        <meta
          property="og:description"
          content="Connect with Quan Freight Management to discover seamless freight solutions across Australia. From Melbourne to major cities, our expert team provides reliable, customer-first logistics services tailored to your needs."
        />
        <meta property="og:image" content="https://qfmlogistics.com.au/images/qfm-get-in-touch.jpg" />
        <meta property="og:url" content="https://qfmlogistics.com.au/get-in-touch" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_AU" />
        <meta property="og:site_name" content="Quan Freight Management" />
        <link rel="canonical" href="https://qfmlogistics.com.au/get-in-touch" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "ContactPage",
                "@id": "https://qfmlogistics.com.au/get-in-touch#webpage",
                "url": "https://qfmlogistics.com.au/get-in-touch",
                "name": "Get in Touch | Logistics Solutions by QFM",
                "description": "Contact Quan Freight Management for reliable logistics and freight services across Australia, including pallets, cartons, and project freight solutions.",
                "publisher": { "@id": "https://qfmlogistics.com.au#organization" },
                "inLanguage": "en-AU",
                "breadcrumb": { "@id": "https://qfmlogistics.com.au/get-in-touch#breadcrumb" }
              },
              {
                "@type": "Organization",
                "@id": "https://qfmlogistics.com.au#organization",
                "name": "Quan Freight Management",
                "url": "https://qfmlogistics.com.au",
                "logo": "https://qfmlogistics.com.au/images/qfm-logo.png",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "contact@qfmlogistics.com.au",
                  "contactType": "Customer Service",
                  "areaServed": "AU"
                },
                "sameAs": [
                  "https://www.linkedin.com/company/qfm-logistics"
                ]
              },
              {
                "@type": "CreativeWork",
                "@id": "https://qfmlogistics.com.au#license",
                "name": "Terms and Conditions",
                "url": "https://qfmlogistics.com.au/terms-and-conditions"
              },
              {
                "@type": "BreadcrumbList",
                "@id": "https://qfmlogistics.com.au/get-in-touch#breadcrumb",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://qfmlogistics.com.au/"
                  },
                  {
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Get in Touch",
                    "item": "https://qfmlogistics.com.au/get-in-touch"
                  }
                ]
              },
              {
                "@type": "ImageObject",
                "contentUrl": "https://qfmlogistics.com.au/images/qfm-get-in-touch.jpg",
                "url": "https://qfmlogistics.com.au/images/qfm-get-in-touch.jpg",
                "description": "Contact Quan Freight Management for reliable logistics and freight services across Australia, including pallets, cartons, and project freight solutions",
                "name": "Get in Touch - Reliable Freight Services Across Australia",
                "author": { "@id": "https://qfmlogistics.com.au#organization" },
                "license": { "@id": "https://qfmlogistics.com.au#license" },
                "creator": { "@id": "https://qfmlogistics.com.au#organization" },
                "copyrightNotice": "© Quan Freight Management",
                "creditText": "Quan Freight Management",
                "acquireLicensePage": "https://qfmlogistics.com.au/terms-and-conditions"
              }
            ]
          })}
        </script>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-C758R2N8C5"></script>
        <script>
          {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-C758R2N8C5');
    `}
        </script>
      </Helmet>
      <div className="getintouch-content-container">
        {/* Alert section */}
        {alertVisible && (
          <CustomAlert
            title={alertTitle}
            message={alertMessage}
            type={alertType}
            onClose={() => setAlertVisible(false)}
          />
        )}
        <Header isScrolledInitially={true} />
        {!isMobile && (
          <div className="vertical-line"></div>
        )}
        <div className="getintouch-intro-section getintouch-intro-section-add appearing-effect">
          <div className="getintouch-intro-section-text-content">
            <h1>Get In Touch</h1>
            <p>
              Whether you have a question about our freight services or need a customised logistics solution,
              our team at Quan Freight Management is here to help. With years of expertise, a customer-first
              approach, and a dedication to innovation, we’re committed to delivering seamless, reliable,
              and efficient transport solutions across Australia.
            </p>
            {!isMobile && (
              <p>
                Reach out to discuss your freight needs, request a quote, or learn more about how we can support
                your business. From Melbourne to Sydney, Brisbane, and beyond, our advanced logistics network
                and dedicated team are ready to assist. Let us partner with you to optimise your supply chain
                and provide reliable, cost-effective logistics solutions you can trust.
              </p>
            )}
          </div>
          <div className="getintouch-intro-section-image-container">
            <img
              src="/images/qfm-get-in-touch.jpg"
              alt="Contact Quan Freight Management for reliable logistics and freight services across Australia, including pallets, cartons, and project freight solutions"
              title="Get in Touch - Reliable Freight Services Across Australia"
              loading="lazy"
            />
          </div>
        </div>

        <div className="getintouch-section-3 appearing-effect">
          <div className="getintouch-section-3-text-content">
            {selectedForm === 'information' ? (
              /* ---------------------------- INFORMATION FORM ---------------------------- */
              <form className="contact-us-form" onSubmit={handleSubmit}>
                <div className="form-row center-row">
                  <div className="form-group radio-group">
                    <label>
                      <input
                        type="radio"
                        id="requestInformation"
                        name="requestType"
                        value="information"
                        defaultChecked
                        onChange={handleRadioChange}
                        // Fix: Use camelCase autoComplete
                        autoComplete="off"
                      />
                      Request Information
                    </label>
                  </div>
                  <div className="form-group radio-group">
                    <label>
                      <input
                        type="radio"
                        id="requestQuote"
                        name="requestType"
                        value="quote"
                        onChange={handleRadioChange}
                        autoComplete="off"
                      />
                      Request Quote
                    </label>
                  </div>
                </div>
                <div className="step-indicator">
                  Fill out the form below or email us at <a href="mailto:contact@qfmlogistics.com.au" style={{ color: "rgb(0,0,128)", textDecoration: "none" }}><b>contact@qfmlogistics.com.au</b></a>.
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="name">
                      Name{formErrors.name && <span className="label-error">{formErrors.name}</span>}
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formValues.name}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      className={formErrors.name ? 'input-error' : ''}
                      autoComplete="name"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="company">
                      Company{formErrors.company && <span className="label-error">{formErrors.company}</span>}
                      <span style={{ fontSize: '0.7rem' }}> (If applicable)</span>
                    </label>
                    <input
                      type="text"
                      id="company"
                      name="company"
                      value={formValues.company}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      className={formErrors.company ? 'input-error' : ''}
                      autoComplete="organization"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="email">
                      Email{formErrors.email && <span className="label-error">{formErrors.email}</span>}
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      className={formErrors.email ? 'input-error' : ''}
                      autoComplete="email"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="phone">
                      Phone{formErrors.phone && <span className="label-error">{formErrors.phone}</span>}
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formValues.phone}
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                      className={formErrors.phone ? 'input-error' : ''}
                      autoComplete="tel"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group full-width">
                    <label htmlFor="remarks">
                      Comments
                      {formErrors.remarks && <span className="error">{formErrors.remarks}</span>}
                    </label>
                    <textarea
                      id="remarks"
                      name="remarks"
                      rows="4"
                      maxLength="280"
                      value={formValues.remarks}
                      onChange={handleInputChange}
                      style={{ resize: 'none' }}
                      autoComplete="off"
                    ></textarea>
                  </div>
                </div>

                <div className="response-time"><span>We will respond within one business day.</span></div>
                <div className="button-row right-aligned">
                  <button type="submit" className="send-button">Send</button>
                </div>
              </form>
            ) : (
              // ---------------------------- QUOTE FORM (Multi-step) ----------------------------
              quoteStep === 1 ? (
                /* -------------- QUOTE FORM - STEP 1 -------------- */
                <form className="contact-us-form">
                  <div className="form-row center-row">
                    <div className="form-group radio-group">
                      <label>
                        <input
                          type="radio"
                          id="requestInformation"
                          name="requestType"
                          value="information"
                          onChange={handleRadioChange}
                          autoComplete="off"
                        />
                        Request Information
                      </label>
                    </div>
                    <div className="form-group radio-group">
                      <label>
                        <input
                          type="radio"
                          id="requestQuote"
                          name="requestType"
                          value="quote"
                          defaultChecked
                          onChange={handleRadioChange}
                          autoComplete="off"
                        />
                        Request Quote
                      </label>
                    </div>
                  </div>
                  <div className="step-indicator">Step 1 of 3 - Personal information</div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="name">
                        Name{quoteFormErrors.name && <span className="label-error">{quoteFormErrors.name}</span>}
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={quoteFormValues.name}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.name ? 'input-error' : ''}
                        autoComplete="name"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="company">
                        Company
                        {quoteFormErrors.company && <span className="label-error">{quoteFormErrors.company}</span>}
                        <span style={{ fontSize: '0.7rem' }}> (If applicable)</span>
                      </label>
                      <input
                        type="text"
                        id="company"
                        name="company"
                        value={quoteFormValues.company}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.company ? 'input-error' : ''}
                        autoComplete="organization"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group full-width">
                      <label htmlFor="address">
                        Address{quoteFormErrors.address && <span className="label-error">{quoteFormErrors.address}</span>}
                        <span style={{ fontSize: '0.7rem' }}> (If applicable)</span>
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        value={quoteFormValues.address}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.address ? 'input-error' : ''}
                        autoComplete="street-address"
                      />
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="email">
                        Email{quoteFormErrors.email && <span className="label-error">{quoteFormErrors.email}</span>}
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={quoteFormValues.email}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.email ? 'input-error' : ''}
                        autoComplete="email"
                      />
                    </div>
                    <div className="form-group" style={{ marginBottom: '21.5px' }}>
                      <label htmlFor="phone">
                        Phone{quoteFormErrors.phone && <span className="label-error">{quoteFormErrors.phone}</span>}
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={quoteFormValues.phone}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.phone ? 'input-error' : ''}
                        autoComplete="tel"
                      />
                    </div>
                  </div>

                  <div className="button-row">
                    <button
                      type="button"
                      className="next-button right-aligned"
                      onClick={handleQuoteNextStep}
                    >
                      Next
                    </button>
                  </div>
                </form>
              ) : quoteStep === 2 ? (
                /* -------------- QUOTE FORM - STEP 2 -------------- */
                <form className="contact-us-form">
                  <div className="form-row center-row">
                    <div className="form-group radio-group">
                      <label>
                        <input
                          type="radio"
                          id="requestInformation"
                          name="requestType"
                          value="information"
                          onChange={handleRadioChange}
                          autoComplete="off"
                        />
                        Request Information
                      </label>
                    </div>
                    <div className="form-group radio-group">
                      <label>
                        <input
                          type="radio"
                          id="requestQuote"
                          name="requestType"
                          value="quote"
                          defaultChecked
                          onChange={handleRadioChange}
                          autoComplete="off"
                        />
                        Request Quote
                      </label>
                    </div>
                  </div>
                  <div className="step-indicator">Step 2 of 3 - Quote details</div>

                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor="pickupDestination">
                        Pickup Suburb + Postcode
                        {quoteFormErrors.pickupDestination && <span className="label-error">{quoteFormErrors.pickupDestination}</span>}
                      </label>
                      <input
                        type="text"
                        id="pickupDestination"
                        name="pickupDestination"
                        value={quoteFormValues.pickupDestination}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.pickupDestination ? 'input-error' : ''}
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="deliveryLocation">
                        Delivery Suburb + Postcode
                        {quoteFormErrors.deliveryLocation && <span className="label-error">{quoteFormErrors.deliveryLocation}</span>}
                      </label>
                      <input
                        type="text"
                        id="deliveryLocation"
                        name="deliveryLocation"
                        value={quoteFormValues.deliveryLocation}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.deliveryLocation ? 'input-error' : ''}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-row three-items">
                    <div className="form-group">
                      <label htmlFor="itemType">
                        Item Type{quoteFormErrors.itemType && <span className="label-error">{quoteFormErrors.itemType}</span>}
                      </label>
                      <select
                        id="itemType"
                        name="itemType"
                        value={quoteFormValues.itemType}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.itemType ? 'input-error' : ''}
                        autoComplete="off"
                      >
                        <option value=""></option>
                        <option value="Envelope">Envelope</option>
                        <option value="Carton">Carton</option>
                        <option value="Skid">Skid</option>
                        <option value="Pallet">Pallet</option>
                        <option value="Crate">Crate</option>
                        <option value="Drum">Barrel/Drum</option>
                        <option value="BulkBag">Bulk Bags</option>
                        <option value="Container">Container</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="weight">
                        Weight{quoteFormErrors.weight && <span className="label-error">{quoteFormErrors.weight}</span>}
                      </label>
                      <input
                        type="number"
                        id="weight"
                        name="weight"
                        value={quoteFormValues.weight}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.weight ? 'input-error' : ''}
                        placeholder="kg"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="quantity">
                        Quantity{quoteFormErrors.quantity && <span className="label-error">{quoteFormErrors.quantity}</span>}
                      </label>
                      <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={quoteFormValues.quantity}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.quantity ? 'input-error' : ''}
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="form-row three-items">
                    <div className="form-group">
                      <label htmlFor="length">
                        Length{quoteFormErrors.length && <span className="label-error">{quoteFormErrors.length}</span>}
                      </label>
                      <input
                        type="number"
                        id="length"
                        name="length"
                        value={quoteFormValues.length}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.length ? 'input-error' : ''}
                        placeholder="cm"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="width">
                        Width{quoteFormErrors.width && <span className="label-error">{quoteFormErrors.width}</span>}
                      </label>
                      <input
                        type="number"
                        id="width"
                        name="width"
                        value={quoteFormValues.width}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.width ? 'input-error' : ''}
                        placeholder="cm"
                        autoComplete="off"
                      />
                    </div>
                    <div className="form-group" style={{ marginBottom: '20px' }}>
                      <label htmlFor="height">
                        Height{quoteFormErrors.height && <span className="label-error">{quoteFormErrors.height}</span>}
                      </label>
                      <input
                        type="number"
                        id="height"
                        name="height"
                        value={quoteFormValues.height}
                        onChange={handleQuoteInputChange}
                        onFocus={handleQuoteInputFocus}
                        className={quoteFormErrors.height ? 'input-error' : ''}
                        placeholder="cm"
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div className="button-row">
                    <button type="button" className="previous-button" onClick={handlePreviousStep}>
                      Previous
                    </button>
                    <button type="button" className="next-button" onClick={handleQuoteNextStep}>
                      Next
                    </button>
                  </div>
                </form>
              ) : (
                /* -------------- QUOTE FORM - STEP 3 -------------- */
                <form className="contact-us-form" onSubmit={handleQuoteSubmit}>
                  <div className="form-row center-row">
                    <div className="form-group radio-group">
                      <label>
                        <input
                          type="radio"
                          id="requestInformation"
                          name="requestType"
                          value="information"
                          onChange={handleRadioChange}
                          autoComplete="off"
                        />
                        Request Information
                      </label>
                    </div>
                    <div className="form-group radio-group">
                      <label>
                        <input
                          type="radio"
                          id="requestQuote"
                          name="requestType"
                          value="quote"
                          defaultChecked
                          onChange={handleRadioChange}
                          autoComplete="off"
                        />
                        Request Quote
                      </label>
                    </div>
                  </div>
                  <div className="step-indicator">Step 3 of 3 - Additional details</div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="hydraulicTailgate"
                          name="hydraulicTailgate"
                          checked={quoteFormValues.hydraulicTailgate}
                          onChange={handleQuoteInputChange}
                          autoComplete="off"
                        />
                        Hydraulic Tailgate required
                      </label>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="residentialDelivery"
                          name="residentialDelivery"
                          checked={quoteFormValues.residentialDelivery}
                          onChange={handleQuoteInputChange}
                          autoComplete="off"
                        />
                        Is this a residential delivery?
                      </label>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="poBoxDelivery"
                          name="poBoxDelivery"
                          checked={quoteFormValues.poBoxDelivery}
                          onChange={handleQuoteInputChange}
                          autoComplete="off"
                        />
                        Is this a PO Box Delivery?
                      </label>
                    </div>
                  </div>

                  <div className="form-row" style={{ marginBottom: '62.5px' }}>
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          id="residentialPickup"
                          name="residentialPickup"
                          checked={quoteFormValues.residentialPickup}
                          onChange={handleQuoteInputChange}
                          autoComplete="off"
                        />
                        Is this a residential pickup?
                      </label>
                    </div>
                  </div>

                  <div className="button-row">
                    <button type="button" className="previous-button" onClick={handlePreviousStep}>
                      Previous
                    </button>
                    <button type="submit" className="submit-button">
                      Submit
                    </button>
                  </div>
                </form>
              )
            )}
          </div>
        </div>

        {isVisible && !isMobile && (
          <div className="scroll-up" onClick={scrollToTop}>
            <i className="fas fa-chevron-up"></i>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
}

export default GetInTouch;